import { TEACHER } from './actions-type';
import {
  CoursesType,
  FutureLessonsType,
  getCoursesAction,
  getStudentsAction,
  LessonsStatusesType,
  TeacherChangeLessonType,
  teacherFutureLessonsAction,
  teacherInfoAction,
  TeacherInfoType,
  teacherInfoUpdateAction,
  TeacherInfoUpdateType,
  teacherLessonsListAction,
  teacherLessonsStatsAction,
  teacherLessonsStatusesAction,
  TeacherStatsType,
  TeacherStudentInfoUpdateType,
} from './actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { authAPI, lessonsAPI, teacherAPI } from '../../API/API';

type InitialState = {
  teacherData: TeacherInfoType;
  students: [];
  teacherFutureLessons: [];
  lessonsStatusesList: [];
  lessonsList: [];
  lessonsStatsData: {};
  coursesData: CoursesType[];
  currentCourse: CoursesType;
  isReady: boolean;
};

const initialState: InitialState = {
  teacherData: null,
  students: [],
  teacherFutureLessons: [],
  lessonsStatusesList: [],
  lessonsList: [],
  lessonsStatsData: {},
  coursesData: [],
  currentCourse: undefined,
  isReady: false,
};
// TODO: action type?
export const teacherReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TEACHER.GET_INFO:
      return {
        ...state,
        teacherData: action.data,
        isReady: true,
      };
    case TEACHER.GET_STUDENTS:
      return {
        ...state,
        students: action.data,
      };
    case TEACHER.GET_FUTURE_LESSONS:
      return {
        ...state,
        teacherFutureLessons: action.data,
      };
    case TEACHER.GET_LESSONS_STATUSES:
      return {
        ...state,
        lessonsStatusesList: action.data,
      };
    case TEACHER.GET_LESSONS_LIST:
      return {
        ...state,
        lessonsList: action.data,
      };
    case TEACHER.GET_LESSONS_STATS:
      return {
        ...state,
        lessonsStatsData: action.data,
      };
    case TEACHER.GET_COURSES:
      return {
        ...state,
        coursesData: action.data,
      };
    case TEACHER.SET_CURRENT_COURSE:
      return {
        ...state,
        currentCourse: action.currentCourse,
      };
    case TEACHER.UPDATE_INFO:
      let { id, email } = state.teacherData;
      let { firstName, lastName, middleName, country, city } = action.data;
      return {
        ...state,
        teacherData: { id, email, firstName, lastName, middleName, country, city },
      };
    default:
      return state;
  }
};

function* fetchTeacherData(): any {
  try {
    const data = yield call(() => {
      return teacherAPI.getCurrentTeacherInfo();
    });
    yield put(teacherInfoAction(data));
  } catch (error) {
    console.log('Fetching error');
  }
}

function* fetchStudentsData(action: {
  type: typeof TEACHER.TRIGGER_GET_STUDENTS;
  id: number;
  course: { teacherCourse: CoursesType };
}): any {
  try {
    const data = yield call(() => {
      return teacherAPI.getStudents(action.id, action.course);
    });
    yield put(getStudentsAction(data));
  } catch (error) {
    console.log('Fetching error');
  }
}

function* fetchLessonStatsData(action: {
  data: TeacherStatsType;
  type: typeof TEACHER.TRIGGER_GET_LESSONS_STATS;
}): any {
  const { id, from, to } = action.data;

  try {
    const data = yield call(() => {
      return lessonsAPI.getLessonsStats({ id, from, to });
    });
    yield put(teacherLessonsStatsAction(data));
  } catch (error) {
    console.log('Fetching error');
  }
}

function* fetchTeacherFutureLessons(action: {
  data: FutureLessonsType;
  type: typeof TEACHER.TRIGGER_GET_FUTURE_LESSONS;
}): any {
  const { fromPlannedDateTime, toPlannedDateTime, teacherIds } = action.data;
  try {
    const futureLessons = yield call(() => {
      return teacherAPI.getLessons({
        fromPlannedDateTime,
        toPlannedDateTime,
        teacherIds,
        status: 'PLANNED',
      });
    });
    const sort = futureLessons.sort(
      (a: any, b: any) =>
        new Date(a.plannedDateTime).getTime() - new Date(b.plannedDateTime).getTime(),
    );
    const newStudentsIds = new Set<number>();
    sort.map((it: any) => newStudentsIds.add(it.student.id));
    const lessonsList = yield call(() => {
      return teacherAPI.getLessons({
        status: 'PLANNED',
        teacherIds,
        studentIds: [...newStudentsIds],
      });
    });
    yield put(teacherFutureLessonsAction(sort));
    yield put(teacherLessonsListAction(lessonsList));
  } catch (error) {
    console.log('Fetching error');
  }
}

function* fetchTeacherLessonsStatuses(action: {
  data: LessonsStatusesType;
  type: typeof TEACHER.TRIGGER_GET_LESSONS_STATUSES;
}): any {
  const { toPlannedDateTime, teacherIds } = action.data;
  try {
    const data = yield call(() => {
      return teacherAPI.getLessons({ status: 'PLANNED', teacherIds, toPlannedDateTime });
    });
    const sort = data.sort(
      (a: any, b: any) =>
        new Date(a.plannedDateTime).getTime() - new Date(b.plannedDateTime).getTime(),
    );
    yield put(teacherLessonsStatusesAction(sort));
  } catch (error) {
    console.log('Fetching error');
  }
}

function* changeTeacherLessonStatus(action: {
  data: TeacherChangeLessonType;
  type: typeof TEACHER.TRIGGER_CHANGE_LESSON_STATUS;
}) {
  const { tId, lId, status, setDisabled } = action.data;
  try {
    yield call(() => {
      setDisabled(true);
      return teacherAPI.changeLessonStatus({ status, tId, lId });
    });
    yield call(() => {
      setDisabled(false);
    });
  } catch (error) {
    console.log('Lesson status change error');
  }
}

function* updateTeacherData(action: {
  type: typeof TEACHER.TRIGGER_UPDATE_INFO;
  data: TeacherInfoUpdateType;
}): any {
  const { id, setSubmitting, setSuccess, setError } = action.data;
  setSubmitting && setSubmitting(true);
  try {
    if (action.data.firstName && action.data.lastName)
      yield call(() => {
        return authAPI.updateUserInfo(action.data.firstName, action.data.lastName); ///?как ошибки обрабатываются обычно?
      });
    yield call(() => {
      return teacherAPI.updateTeacherInfo(action.data, id); ///?как ошибки обрабатываются обычно?
    });
    yield put(teacherInfoUpdateAction(action.data));
    const data = yield call(() => {
      return teacherAPI.getCurrentTeacherInfo();
    });
    yield put(teacherInfoAction(data));
    setSuccess && setSuccess(true);
  } catch (error) {
    setError && setError(true);
  } finally {
    setSubmitting && setSubmitting(false);
  }
}

function* updateTeacherStudentData(action: {
  type: typeof TEACHER.TRIGGER_STUDENT_UPDATE_INFO;
  data: TeacherStudentInfoUpdateType;
  id: number;
  cardId: number;
  course: { teacherCourse: CoursesType };
}): any {
  try {
    yield call(() => {
      return teacherAPI.updateTeacherStudentInfo(action.data, action.id, action.cardId);
    });
    const data = yield call(() => {
      return teacherAPI.getStudents(action.id, action.course);
    });
    yield put(getStudentsAction(data));
  } catch (error) {
    console.log('Teacher students update error');
  }
}

function* getTeacherCourses(action: { type: typeof TEACHER.TRIGGER_GET_COURSES; id: number }): any {
  try {
    const data = yield call(() => {
      return teacherAPI.getCourses(action.id);
    });
    yield put(getCoursesAction(data));
  } catch (error) {
    console.log('Courses getter error');
  }
}

function* setTeacherCourse(action: {
  type: typeof TEACHER.TRIGGER_SET_COURSES;
  id: number;
  data: CoursesType;
}): any {
  try {
    yield call(() => {
      return teacherAPI.setCourse(action.id, action.data);
    });
    const data = yield call(() => {
      return teacherAPI.getCurrentTeacherInfo();
    });
    yield put(teacherInfoAction(data));
  } catch (error) {
    console.log('Courses setter error');
  }
}

function* deleteTeacherCourse(action: {
  type: typeof TEACHER.TRIGGER_DELETE_COURSES;
  id: number;
  data: CoursesType;
}): any {
  try {
    yield call(() => {
      return teacherAPI.deleteCourses(action.id, action.data);
    });
    const data = yield call(() => {
      return teacherAPI.getCurrentTeacherInfo();
    });
    yield put(teacherInfoAction(data));
  } catch (error) {
    console.log('Courses delete error');
  }
}

function* addStudentBalance(action: {
  type: typeof TEACHER.TRIGGER_ADD_BALANCE;
  id: number;
  studentId: number;
  count: number;
  course: CoursesType;
}): any {
  try {
    yield call(() => {
      return teacherAPI.addBalance(action.id, action.studentId, action.count);
    });
    const data = yield call(() => {
      return teacherAPI.getStudents(action.id, { teacherCourse: action.course });
    });
    yield put(getStudentsAction(data));
  } catch (error) {
    console.log('Add balance error');
  }
}

export function* watchTeacherSaga() {
  yield takeEvery(TEACHER.TRIGGER_GET_INFO, fetchTeacherData);
  yield takeEvery(TEACHER.TRIGGER_GET_STUDENTS, fetchStudentsData);
  yield takeEvery(TEACHER.TRIGGER_GET_LESSONS_STATUSES, fetchTeacherLessonsStatuses);
  yield takeEvery(TEACHER.TRIGGER_GET_FUTURE_LESSONS, fetchTeacherFutureLessons);
  yield takeEvery(TEACHER.TRIGGER_CHANGE_LESSON_STATUS, changeTeacherLessonStatus);
  yield takeEvery(TEACHER.TRIGGER_GET_LESSONS_STATS, fetchLessonStatsData);
  yield takeEvery(TEACHER.TRIGGER_UPDATE_INFO, updateTeacherData);
  yield takeEvery(TEACHER.TRIGGER_STUDENT_UPDATE_INFO, updateTeacherStudentData);
  yield takeEvery(TEACHER.TRIGGER_GET_COURSES, getTeacherCourses);
  yield takeEvery(TEACHER.TRIGGER_SET_COURSES, setTeacherCourse);
  yield takeEvery(TEACHER.TRIGGER_DELETE_COURSES, deleteTeacherCourse);
  yield takeEvery(TEACHER.TRIGGER_ADD_BALANCE, addStudentBalance);
}
