import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  wrapper1: {
    display: 'flex',
    width: '1050px',
    height: '720px',
    backgroundColor: '#F5F5FB',
  },
}));
