import React, {FC, MouseEventHandler, useCallback, useEffect, useState} from 'react';
import {useStyles} from '../CreateLessonStyle';
import {
    ClickAwayListener,
    debounce,
    Grow,
    InputAdornment, MenuItem,
    MenuList,
    Paper, Popper,
    TextField,
    Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {CoursesType, TeacherInfoType} from "../../../ducs/teacher/actions";
import {lessonsAPI} from "../../../API/API";
import StyledChip from "../../../Components/StyledChip/StyledChip";
import TuneIcon from '@mui/icons-material/Tune';

const emptySearchMessage = 'Не нашли подходящий раздел? Добавьте новый. Введите полностью название нового раздела и нажмите кнопку ввода. Напоминаем, раздел может включать в себя n-ое количество тем одинаковых по смыслу.';

type Props = {
    articleName: string;
    section: CoursesType[];
    teacherCourse: CoursesType;
    setArticleName: (value: ((prevState: string) => string) | string) => void;
    setSection: (value: ((prevState: CoursesType[]) => CoursesType[]) | CoursesType[]) => void;
    teacherData: TeacherInfoType;
};

const Step1: FC<Props> = (props) => {
    const {articleName, section, setArticleName, setSection, teacherCourse, teacherData} = props;
    const [sections, setSections] = useState<CoursesType[]>([]);
    const [filter, setFilter] = useState<number>(1);
    const [filteredSections, setFilteredSections] = useState<CoursesType[]>([]);
    const [searchVal, setSearchVal] = useState<string>('');
    const [filteredVal, setFilteredVal] = useState<any>();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const classes = useStyles();

    const getCourses = () => {
        lessonsAPI.searchContentSection({
            courseName: teacherCourse.value,
            owner: teacherCourse.owner
        }).then((res: CoursesType[]) => {
            setSections(res);
            setFilteredSections(res);
        });
    }

    useEffect(() => {
        getCourses();
    }, [])

    useEffect(() => {
        let val = searchVal?.length ? filteredSections?.filter(it => it.value.toLocaleLowerCase().includes(searchVal.toLocaleLowerCase())) : '';
        if (!val.length && searchVal.length) val = emptySearchMessage;
        setFilteredVal(val);
    }, [filteredSections])

    useEffect(() => {
        switch (filter) {
            case 2: {
                setFilteredSections(sections.filter(item => item.owner === `${teacherData.id}`));
                break;
            }
            case 3: {
                setFilteredSections([]);
                break;
            }
            case 1:
            default: {
                setFilteredSections(sections);
            }
        }
    }, [filter])

    const onEnterClick = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            lessonsAPI.postContentSection({value: searchVal, course: teacherCourse}).then(() => getCourses());
        }
    }

    const handleFilterClick: MouseEventHandler<HTMLLIElement> = (event) => {
        setFilter(event.currentTarget.value);
        handleClose();
    }

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchVal(e.target.value);
        debounce(() => {
            let value: any = e.target.value ?
                filteredSections?.filter(it => it.value.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase())) :
                [];
            if (!value.length && e.target.value?.length) {
                value = emptySearchMessage;
            }
            setFilteredVal(value);
        }, 1000)();
    }

    const chipsData = useCallback(() => {
        return Array.isArray(filteredVal) ? filteredVal.map(it => <StyledChip
                isSelected={section?.length ? it.value === section[0].value : undefined}
                onClick={() => setSection(section?.length && it.value === section[0].value ? [] : [it])}
                label={it.value}
            />) :
            filteredVal
    }, [filteredVal, section]);

    return (
        <div>
            <Typography align="center" variant="h6">Вводные данные</Typography>
            <div className={classes.stepContentWrapper}>
                <TextField
                    variant="standard"
                    size="small"
                    value={articleName}
                    onChange={(e) => setArticleName(e.currentTarget.value)}
                    classes={{root: classes.step1textField}}
                    label="Название темы"/>
                <div className={classes.filterField}>
                    <TextField
                        variant="standard"
                        classes={{root: classes.step1textField}}
                        placeholder="Введите название раздела для поиска"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon/>
                                </InputAdornment>
                            ),
                        }}
                        value={searchVal}
                        onChange={handleOnChange}
                        onKeyDown={onEnterClick}/>
                    <div
                        onClick={handleClick}
                        aria-controls={open ? 'popper-filter' : undefined}
                        aria-haspopup="true">
                        <TuneIcon className={classes.filter}/>
                    </div>
                    <Popper
                        open={open}
                        anchorEl={anchorEl}
                        transition
                    >
                        {({TransitionProps, placement}) => (
                            <Grow
                                {...TransitionProps}
                                style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList autoFocusItem={open} id="popper-filter">
                                            <MenuItem onClick={handleFilterClick} selected={filter === 1} value={1}>
                                                <Typography variant="body1">Показать все разделы</Typography>
                                            </MenuItem>
                                            <MenuItem onClick={handleFilterClick} selected={filter === 2} value={2}>
                                                <Typography variant="body1">Показать мои разделы</Typography>
                                            </MenuItem>
                                            <MenuItem onClick={handleFilterClick} selected={filter === 3} value={3}>
                                                <Typography variant="body1">Скрыть разделы</Typography>
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </div>
                <div>
                    {chipsData()}
                </div>
            </div>
        </div>
    );
};

export default Step1;
