import { createStyles, makeStyles } from '@mui/styles';

export const muiStyle = {
    paperButton: {
        backgroundColor: '#5E81F4 !important',
        color: 'white !important',
        '&:hover': {
            backgroundColor: '#5E81F4 !important',
        },
    },
    paper: {
        display: 'flex',
        justifyContent: 'center'
    },
    paperPastLesson: {
        display: 'flex',
        height: '50px',
        width: '95%',
        margin: '0 20px',
    },
    welcomeWords: {
        position: 'absolute',
        color: 'black',
        fontWeight: 400,
        fontSize: '14px',
        top: '45%',
        left: '5%',
    },
    welcomeUser: {
        position: 'absolute',
        color: '#FF808B',
        fontWeight: 'bold',
        fontSize: '17px',
        top: '15%',
        left: '5%',
    },
}

export const useStyles = makeStyles(() =>
  createStyles({
    dashWrapper: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    dashMidWrapper: {
      width: '750px',
      backgroundColor: '#F5F5FB',
    },
    welcome: {
      display: 'flex',
      position: 'relative',
      background: '#F7E5E9',
      width: '700px',
      height: '100px',
      margin: '10px 25px',
      borderRadius: '17px',
    },
    paper: {
      height: '300px',
      width: '250px',
    },
    paperContentWrapper: {
      marginTop: '20px',
    },
    paperImg: {
      width: '100px',
      height: '100px',
      borderRadius: '20px',
    },
    paperImgWrapper: {
      display: 'flex',
      justifyContent: 'center',
      padding: '20px 0',
    },
    paperButtonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '30px',
    },
    paperPastLessonWrapper: {
      display: 'flex',
    },
    paperPastLessonImgWrapper: {
      display: 'flex',
      margin: '0 20px',
    },
    paperPastLessonImg: {
      width: '50px',
      height: '50px',
      borderRadius: '20px',
    },
    paperPastContentWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '600px',
      margin: '0 10px',
    },
    paperPastButtonWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '40%',
    },
    horBar: {
      width: '90px',
      height: '15px',
      marginLeft: '25px',
    },
    dashRightWrapper: {
      position: 'relative',
      width: '300px',
      backgroundColor: 'white',
      minHeight: '100vh',
    },
    lessonStat: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    logoutText: {
      fontSize: '14px',
      color: '#111026',
      fontWeight: 'bold',
    },
    logoWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: '350px',
    },
    reminderWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '300px',
      margin: '60px 0 10px 0',
      padding: '0 33px',
    },
    reminderPic: {
      color: '#A2A2C2',
    },
    reminder: {
      display: 'flex',
      width: '260px',
      margin: '10px 0 10px 5px',
    },
    reminderText: {
      fontWeight: 'normal',
      fontSize: '14px',
      marginLeft: '15px',
      color: '#131336',
    },
    reminderDate: {
      fontWeight: 'normal',
      fontSize: '12px',
      marginLeft: '15px',
      color: '#A2A2C2',
    },
    reminderIcon: {
      paddingTop: '10px',
      color: '#6083F4',
    },
    carouselWrap: {
      display: 'inline-flex',
      margin: '10px 0 10px 83px',
    },
  }),
);
