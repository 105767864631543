export type Questions = Question[];

export type Question = {
  questionText: string;
  answerOptions: AnswerOption[];
  correctAnswer: string;
  isInput?: boolean;
};

export type AnswerOption = {
  answerText: string;
  isCorrect: boolean;
};

export const questions = [
  {
    questionText: 'What is the capital of France?',
    answerOptions: [
      { answerText: 'New York', isCorrect: false },
      { answerText: 'London', isCorrect: false },
      { answerText: 'Paris', isCorrect: true },
      { answerText: 'Dublin', isCorrect: false },
    ],
    correctAnswer: 'Paris',
    isInput: false,
  },
  {
    questionText: 'Who is CEO of Tesla?',
    answerOptions: [
      { answerText: 'Jeff Bezos', isCorrect: false },
      { answerText: 'Elon Musk', isCorrect: true },
      { answerText: 'Bill Gates', isCorrect: false },
      { answerText: 'Tony Stark', isCorrect: false },
    ],
    correctAnswer: 'Elon Musk',
    isInput: false,
  },
  {
    questionText: 'The iPhone was created by which company?',
    answerOptions: [
      { answerText: 'Apple', isCorrect: true },
      { answerText: 'Intel', isCorrect: false },
      { answerText: 'Amazon', isCorrect: false },
      { answerText: 'Microsoft', isCorrect: false },
    ],
    correctAnswer: 'Apple',
    isInput: false,
  },
  {
    questionText: 'How many Harry Potter books are there?',
    answerOptions: [
      { answerText: '1', isCorrect: false },
      { answerText: '4', isCorrect: false },
      { answerText: '6', isCorrect: false },
      { answerText: '7', isCorrect: true },
    ],
    correctAnswer: '7',
    isInput: false,
  },
  {
    questionText: '2+2?',
    answerOptions: [],
    correctAnswer: '4',
    isInput: true,
  },
];
