import React, {FC} from 'react';
import {useStyles} from '../StudentsPageStyle';
import {Formik} from 'formik';
import * as Yup from "yup";
import InputRow from '../../../Components/InputRow/InputRow';
import {Button} from '@mui/material';
import {
    CoursesType,
    StudentsType,
    triggerAddBalanceAction,
    triggerTeacherStudentInfoUpdateAction,
} from '../../../ducs/teacher/actions';

export type StudentPersonalInfoProps = {
  dispatch(action: any): void;
  chosenStudent: StudentsType;
  teacherId: number;
  cardId: number;
  isEdit: boolean;
  setEdit(val: boolean): void;
  currentTeacherCourse: CoursesType;
};

const StudentPersonalInfo: FC<StudentPersonalInfoProps> = (props) => {
  const { chosenStudent, dispatch, teacherId, cardId, setEdit, currentTeacherCourse } = props;

  const classes = useStyles();

  const onAddBalance = (balanceChanger: number) => () =>
    dispatch(
      triggerAddBalanceAction(
        teacherId,
        cardId,
        Number(balanceChanger),
        currentTeacherCourse,
      ),
    );

  return (
    <div className={classes.infoWrapper}>
      <img
        src="https://mir-s3-cdn-cf.behance.net/project_modules/disp/ea7a3c32163929.567197ac70bda.png"
        alt="logo"
        width={100}
        height={100}
        className={classes.infoImg}
      />
      <Formik
          initialValues={{
            firstName: chosenStudent?.firstName,
            lastName: chosenStudent?.lastName,
            middleName: chosenStudent?.middleName,
            country: chosenStudent?.country,
            city: chosenStudent?.city,
            level: chosenStudent?.course?.value,
            balance: chosenStudent?.balance,
            balanceChanger: undefined,
            phone: chosenStudent?.phone,
            email: chosenStudent?.email,
          }}
          validationSchema={Yup.object({
            phone: Yup.string().required('Обязательное поле'),
          })}
          onSubmit={
            async (values, { setSubmitting }) => {
              setSubmitting(true);
              await dispatch(
                  triggerTeacherStudentInfoUpdateAction(
                      { phone: values.phone, parent: chosenStudent?.parent ? { ...chosenStudent?.parent } : undefined },
                      teacherId,
                      cardId,
                      { teacherCourse: currentTeacherCourse },
                  ),
              );
              setSubmitting(false);
            }
          }
      >
          {({
              values,
              errors,
              touched,
              dirty,
              handleChange,
                handleBlur,
              handleSubmit,
              isSubmitting, }) => (
      <form onSubmit={handleSubmit}>
        <InputRow
          inputsArray={[
            {
              name: 'lastName',
              id: 'lastName',
              value: values.lastName,
              onChange: handleChange,
                onBlur: handleBlur,
              label: 'Фамилия',
              disabled: true,
                errors,
                touched,
            },
            {
              name: 'firstName',
              id: 'firstName',
              value: values.firstName,
              onChange: handleChange,
                onBlur: handleBlur,
              label: 'Имя',
              disabled: true,
                errors,
                touched,
            },
            {
              name: 'middleName',
              id: 'middleName',
              value: values.middleName,
              onChange: handleChange,
                onBlur: handleBlur,
              label: 'Отчество',
              disabled: true,
                errors,
                touched,
            },
          ]}
        />
        <InputRow
          inputsArray={[
            {
              name: 'country',
              id: 'country',
              value: values.country,
              onChange: handleChange,
                onBlur: handleBlur,
              label: 'Страна',
              disabled: true,
                errors,
                touched,
            },
            {
              name: 'city',
              id: 'city',
              value: values.city,
              onChange: handleChange,
                onBlur: handleBlur,
              label: 'Город',
              disabled: true,
                errors,
                touched,
            },
          ]}
        />
        <InputRow
          inputsArray={[
            {
              name: 'level',
              id: 'level',
              value: values.level,
              onChange: handleChange,
                onBlur: handleBlur,
              label: 'Класс',
              disabled: true,
                errors,
                touched,
            },
          ]}
        />
        <div className={classes.addBalanceWrapper}>
          <InputRow
            inputsArray={[
              {
                name: 'balance',
                id: 'balance',
                value: values.balance,
                onChange: handleChange,
                  onBlur: handleBlur,
                label: 'Баланс занятий',
                disabled: true,
                  errors,
                  touched,
              },
              {
                name: 'balanceChanger',
                id: 'balanceChanger',
                value: values.balanceChanger,
                onChange: handleChange,
                  onBlur: handleBlur,
                label: 'Добавить занятия',
                  errors,
                  touched,
              },
            ]}
          />
          <div className={classes.addBallanceButton}>
            <Button
              color="primary"
              variant="contained"
              disabled={!values.balanceChanger}
              fullWidth
              onClick={onAddBalance(values.balanceChanger)}
            >
              Добавить
            </Button>
          </div>
        </div>
        <InputRow
          inputsArray={[
            {
              name: 'phone',
              id: 'phone',
              value: values.phone,
              onChange: handleChange,
                onBlur: handleBlur,
              label: 'Контактный телефон',
                errors,
                touched,
            },
            {
              name: 'email',
              id: 'email',
              value: values.email,
              onChange: handleChange,
                onBlur: handleBlur,
              label: 'Почта',
              disabled: true,
                errors,
                touched,
            },
          ]}
        />
        <div>
          <Button
            sx={{
                maxWidth: '100%',
                width: 'fit-content',
                marginTop: '20px',
                backgroundColor: '#5E81F4',
            }}
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={!dirty || isSubmitting}
          >
            Сохранить информацию
          </Button>
          <Button
              sx={{
                  maxWidth: '100%',
                  width: 'fit-content',
                  margin: '20px 0 0 20px',
              }}
            color="primary"
            variant="outlined"
            fullWidth
            onClick={() => setEdit(false)}
            disabled={isSubmitting}
          >
            Вернуться в карточку
          </Button>
        </div>
      </form>
          )}
      </Formik>
    </div>
  );
};

export default StudentPersonalInfo;
