import React, { FC, useEffect, useState } from 'react';
import {muiStyles, useStyles} from '../ScheduleStyle';
import {
  Button,
  ButtonGroup,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
    Paper
} from '@mui/material';
import { DayPicker } from 'react-day-picker';
import { ru } from 'date-fns/locale';
import styles from 'react-day-picker/dist/style.module.css';
import { NavLink, Redirect } from 'react-router-dom';
import {
  CoursesType,
  StudentsType,
  TeacherInfoType,
  triggerGetStudentsAction,
} from '../../../ducs/teacher/actions';
import { AppStateType } from '../../../ducs';
import { connect } from 'react-redux';
import { triggerCreateLessonSchedule } from '../../../ducs/calendar/actions';
import {
  buttonsValues,
  dayNames,
  getEndDate,
  getFromZonedDateTime,
  getTimeDiff,
} from '../../../utils/calendarUtils';
import { calendarDataPost } from 'src/API/API';

type Props = {
  teacherData: TeacherInfoType;
  students: StudentsType[];
  currentCourse: CoursesType;
  dispatch: any;
};

const AddScheduleLesson: FC<Props> = (props) => {
  const { dispatch, teacherData, students = [], currentCourse } = props;
  const [redirect, setRedirect] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [startTime, setStartTime] = useState<string>('');
  const [studentId, setStudentId] = useState<number>(null);
  const [endTime, setEndTime] = useState<string>('');
  const [buttons, setButtons] = useState<string>('');
  const [repeatable, setRepeatable] = useState<boolean>(false);
  const [day, setDay] = useState<Date>();
  const classes = useStyles();
  const isButtonActive = studentId && startTime && endTime && buttons && day;

  const onButtonSelect = (e: any) => {
    e.target.textContent === 'Не повторять' ? setRepeatable(false) : setRepeatable(true);
    setButtons(e.target.textContent);
  };

  const disabledCallback = (date: Date) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);
    return  date < currentDate;
  }

  const onSaveClick = () => {
    setDisabled(true);
    const data: calendarDataPost = {
      teacherId: teacherData.id,
      studentId,
      subject: 'Geometry',
      activityType: 'SIMPLE',
      fromDateTime: getFromZonedDateTime(startTime, day),
      endDateTime: getEndDate(day, repeatable, buttons),
      durationMinutes: getTimeDiff(startTime, endTime),
      repeatable,
      repeatWeekDays: [dayNames[day.getDay()]],
    };
    dispatch(triggerCreateLessonSchedule({ ...data, setRedirect }));
  };

  const dayPickerClasses = { ...styles, day_selected: classes.daySelected };

  useEffect(
    () =>
        currentCourse &&
      dispatch(triggerGetStudentsAction(teacherData.id, { teacherCourse: currentCourse })),
    [currentCourse],
  );

  if (redirect) return <Redirect to="/schedule" />;

  return (
    <div className={classes.addLessonWrapper}>
      <div className={classes.innerAddLessonWrapper}>
        <FormControl variant="standard" sx={muiStyles.formControl}>
          <InputLabel id="select-schedule-input-label">Выбрать ученика</InputLabel>
          <Select
            variant="standard"
            labelId="select-schedule-label"
            id="select-schedule"
            //@ts-ignore
            value={studentId}
            onChange={(e) => setStudentId(Number(e.target.value))}>
            {students?.map((it) => (
              <MenuItem value={it.id} key={it.id}>
                {`${it.firstName} ${it.lastName}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Paper sx={muiStyles.paperContentWrapper}>
          <div className={classes.upWrapper}>
            <div className={classes.buttonGroupWrapper}>
              <Typography align="center" variant="subtitle1">
                Повторять:
              </Typography>
              <ButtonGroup
                orientation="vertical"
                sx={{color: '#5E81F4'}}
                aria-label="add schedule button group"
                variant="text"
                onClick={(e) => onButtonSelect(e)}
              >
                {Object.getOwnPropertyNames(buttonsValues).map((it) => (
                  <Button key={it}>{it}</Button>
                ))}
              </ButtonGroup>
            </div>
            <div className={classes.dayPickerWrapper}>
              <DayPicker
                classNames={dayPickerClasses}
                selected={day}
                onSelect={setDay}
                mode="single"
                numberOfMonths={2}
                locale={ru}
                disabled={(date) => disabledCallback(date)}
              />
              <div className={classes.timePickerWrapper}>
                <TextField
                  variant="standard"
                  id="time-start"
                  label="Начало урока"
                  type="time"
                  value={startTime}
                  onChange={(e) => setStartTime(e.currentTarget.value)}
                  sx={{
                    display: 'flex',
                    width: '200px',
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300,
                  }} />
                <TextField
                  variant="standard"
                  id="time-end"
                  label="Окончание урока"
                  type="time"
                  value={endTime}
                  onChange={(e) => setEndTime(e.currentTarget.value)}
                  sx={{
                    display: 'flex',
                    width: '200px',
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300,
                  }} />
              </div>
            </div>
          </div>
          <div className={classes.bottomWrapper}>
            <Typography variant="caption" sx={muiStyles.repeatText}>
              Повтор: {buttons || 'Не выбрано'}
            </Typography>
            <NavLink to="/schedule">
              <Button sx={muiStyles.exitButton}>Выйти</Button>
            </NavLink>
            <Button
              sx={muiStyles.saveButton}
              disabled={!isButtonActive || disabled}
              onClick={onSaveClick}
            >
              Сохранить
            </Button>
          </div>
        </Paper>
      </div>
    </div>
  );
};

type MapStateToPropsType = {
  teacherData: TeacherInfoType;
  students: StudentsType[];
  currentCourse: CoursesType;
};

let MapStateToProps = (state: AppStateType) => ({
  teacherData: state.teacherData.teacherData,
  students: state.teacherData.students,
  currentCourse: state.teacherData.currentCourse,
});

export default connect<MapStateToPropsType, null, {}, AppStateType>(MapStateToProps)(
  AddScheduleLesson,
);
