import { FC, useEffect, useState } from 'react';
import { useStyles } from '../StudentsPageStyle';
import TabsWrappedLabel from '../../../Components/TabPanel';
import StudentPersonalInfo from './StudentPersonalInfo';
import StudentParentsInfo from './StudentParentsInfo';
import StudentCard from './StudentCard';
import { CoursesType, StudentsType, TeacherInfoType } from '../../../ducs/teacher/actions';
import { AppStateType } from '../../../ducs';
import { connect } from 'react-redux';
import { calendarDataGet } from '../../../API/API';
import { triggerGetLessonSchedule } from '../../../ducs/calendar/actions';

type StudentTypesProps = {
  dispatch(action: any): void;
  chosenStudent: StudentsType;
  cardId: number;
  teacherData: TeacherInfoType;
  appointmentsData: calendarDataGet[];
  students: StudentsType[];
  currentCourse: CoursesType;
};

const StudentsTab: FC<StudentTypesProps> = ({
  chosenStudent,
  dispatch,
  cardId,
  teacherData,
  students,
                                              currentCourse,
}) => {
  const [isEdit, setEdit] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    dispatch(triggerGetLessonSchedule(teacherData.id, students));
  }, []);

  return (
    <div className={classes.tabRoot}>
      {isEdit ? (
        <TabsWrappedLabel
          abbBarColor="default"
          indicatorColor="primary"
          textColor="primary"
          centered
          labeles={['Об ученике', 'О родителе']}
          content={[
            <StudentPersonalInfo
              chosenStudent={chosenStudent}
              dispatch={dispatch}
              cardId={cardId}
              teacherId={teacherData.id}
              setEdit={setEdit}
              isEdit={isEdit}
              currentTeacherCourse={currentCourse}
            />,
            <StudentParentsInfo
              chosenStudent={chosenStudent}
              dispatch={dispatch}
              cardId={cardId}
              teacherId={teacherData.id}
              setEdit={setEdit}
              isEdit={isEdit}
              currentTeacherCourse={currentCourse}
            />,
          ]}
        />
      ) : (
        <StudentCard
          chosenStudent={chosenStudent}
          setEdit={setEdit}
          isEdit={isEdit}
          dispatch={dispatch}
        />
      )}
    </div>
  );
};

type MapStateToPropsType = {
  teacherData: TeacherInfoType;
  appointmentsData: calendarDataGet[];
  currentCourse: CoursesType;
};

let MapStateToProps = (state: AppStateType) => ({
  teacherData: state.teacherData.teacherData,
  appointmentsData: state.calendarData.appointmentsData,
  currentCourse: state.teacherData.currentCourse,
});

export default connect<MapStateToPropsType, null, {}, AppStateType>(MapStateToProps)(StudentsTab);
