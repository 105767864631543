import LeftSidePanel from '../Content/LeftSidePanel/LeftSidePanel';
import React, {FC, useEffect} from 'react';
import { useStyles } from './AppStyle';
import DashboardStat from '../Content/DashboardStat/DashboardStat';
import { CircularProgress } from '@mui/material';
import { Route } from 'react-router-dom';
import Lessons from '../Content/Lessons/Lessons';
import HomeWork from '../Content/HomeWork/HomeWork';
import SchedulePage from '../Content/Schedule/SchedulePage';
import StudentsPage from 'src/Content/students/StudentsPage';
import UpBar from '../Components/UpBar/UpBar';
import CreateLesson from '../Content/CreateLesson/CreateLesson';
import PersonalAccount from '../Content/PersonalAccount/PersonalAccount';
import Swagger from '../Components/Swagger';
import { AppStateType } from '../ducs';
import { connect } from 'react-redux';
import {TeacherInfoType, teacherSetCourseAction} from '../ducs/teacher/actions';
import AddStudentPage from '../Content/students/components/AddStudentPage';
import StudentProfile from "../StudentContent/Profile/StudentProfile";
import { Theme } from '@mui/material/styles';

declare module '@mui/styles' {
  interface DefaultTheme extends Theme {}
}

type Props = {
  teacherData: TeacherInfoType;
  isReady: boolean;
  dispatch: any;
};

const App: FC<Props> = (props) => {
  const classes = useStyles();

  useEffect(() => {
    const course = JSON.parse(localStorage.getItem('currentCourse'));
    props.dispatch(teacherSetCourseAction(course));
  }, [])

  return !props.isReady ? (
    <div className={classes.loader}><CircularProgress /></div>
  ) : (
    <div className={classes.appWrapper}>
      <div className={classes.scrollContainer}>
            <div className={classes.appContent}>
              <LeftSidePanel />
              <div className={classes.innerContent}>
                <UpBar teacherData={props.teacherData} />
                <Route exact path="/createLesson" render={() => <CreateLesson />} />
                <Route exact path="/lessons/:lessonId?" render={() => <Lessons />} />
                <Route path="/addStudent" render={() => <AddStudentPage />} />
                <Route path="/main" render={() => <DashboardStat />} />
                <Route path="/students/:userId?" render={() => <StudentsPage />} />
                <Route path="/schedule/:addLesson?" render={() => <SchedulePage />} />
                <Route path="/personalAccount/:info?" render={() => <PersonalAccount />} />
                <Route path="/homework/:lessonId?" render={() => <HomeWork />} />
                <Route path="/swagger" render={() => <Swagger />} />
                <Route path="/student/profile" render={() => <StudentProfile />} />
              </div>
            </div>
      </div>
    </div>
  );
};

type MapStateToPropsType = {
  teacherData: TeacherInfoType;
  isReady: boolean;
};

let MapStateToProps = (state: AppStateType) => ({
  teacherData: state.teacherData.teacherData,
  isReady: state.teacherData.isReady,
});

export default connect<MapStateToPropsType, null, {}, AppStateType>(MapStateToProps)(App);
