import { Box, Typography, CssBaseline } from '@mui/material';
import classNames from 'classnames';
import 'typeface-poppins';
import { useStyles } from './LeftSidePanelStyle';
import { menuItems } from '../../MOK/menuItems';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { MatchParams } from 'src/types/router';
import { FC } from 'react';
import {css} from "@emotion/css";

type Props = {} & RouteComponentProps<MatchParams>;

const LeftSidePanel: FC<Props> = (props) => {
  const classes = useStyles();
  const menuID = props.location.pathname;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Box>
        <div className={classes.boxPosition}>
          <Typography className={css`
                margin: 0 0 5% 15%;
                font-weight: 600;
                font-size: 21px;
                line-height: 85px;
          `}>EDURoom</Typography>
        </div>
        {menuItems.map((u) => {
          return (
            <div key={u.label} className={classes.boxPosition}>
              {u.icon(classes.icon)}
              <NavLink
                to={u.link}
                className={classNames(classes.menu, {
                  [classes.mainMenu]: u.link === menuID,
                })}
              >
                {u.label}
              </NavLink>
            </div>
          );
        })}
      </Box>
    </div>
  );
};

export default withRouter(LeftSidePanel);
