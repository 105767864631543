import { format } from 'date-fns';
import { calendarDataGet, calendarUpdateType } from '../API/API';
import { StudentsType } from '../ducs/teacher/actions';

export const dayNames = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];

export const buttonsValues = {
  'Не повторять': 0,
  'Этот месяц': 1,
  '3 месяца': 3,
  '6 месяцев': 6,
  '1 год': 12,
};

export const getTimeDiff = (startTime: string, endTime: string) => {
  const start = startTime.split(':');
  const end = endTime.split(':');
  // @ts-ignore
  const startDate = new Date(0, 0, 0, start[0], start[1], 0);
  // @ts-ignore
  const endDate = new Date(0, 0, 0, end[0], end[1], 0);
  const diff = endDate.getTime() - startDate.getTime();
  return Math.floor(diff / 1000 / 60);
};

export const getFromZonedDateTime = (startTime: string, currentDay: Date) => {
  const startHour = Number(startTime.substring(0, 2));
  const startMinutes = Number(startTime.substring(3));
  const fromZonedDateTime = new Date(currentDay);
  fromZonedDateTime?.setHours(startHour, startMinutes);
  return format(fromZonedDateTime, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
};

export const getEndDate = (currentDay: Date, repeatable: boolean, button: string | number) => {
  let buttonVal = button;
  if (!Number.isInteger(button)) buttonVal = buttonsValues[button as keyof typeof buttonsValues];
  if (!repeatable) return currentDay ? format(currentDay, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
  const endDate = new Date(currentDay);
  if (buttonVal === 1) {
    endDate.setMonth(endDate.getMonth() + 1);
    endDate.setDate(1);
    return format(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
  }
  // @ts-ignore
  endDate.setMonth(endDate.getMonth() + buttonVal);
  return format(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
};

export const getPreparedAppointment = (
  value: calendarDataGet | calendarUpdateType,
  currStudent: StudentsType,
) => {
  // @ts-ignore
  const dateTime = `${(value.fromLocalDate ?? value.newDate)} ${value.localTime} UTC`;
  const title = `${currStudent.firstName} ${currStudent.lastName}`;
  const startDate = new Date(dateTime);
  const endDate = new Date(dateTime);
  // @ts-ignore
  endDate.setMinutes(endDate.getMinutes() + (value.durationMinutes || value.duration));

  return { title, startDate, endDate };
};

export const monthDiff = (dateFrom: Date, dateTo: Date) => {
  return (
    dateTo.getMonth() - dateFrom.getMonth() + 12 * (dateTo.getFullYear() - dateFrom.getFullYear())
  );
};

export const calculateTime = ({
  time,
  addHours,
  addMinutes,
  addTime = true,
}: {
  time: string;
  addHours?: number;
  addMinutes?: number;
  addTime?: boolean;
}) => {
  const timeToMinutes = Number(time.slice(0, 2)) * 60 + Number(time.slice(3, 5));
  const addition = addHours ? addHours * 60 : addMinutes;
  const resultMinutes = addTime ? timeToMinutes + addition : timeToMinutes - addition;
  const hh = Math.floor(resultMinutes / 60);
  const mm = resultMinutes % 60;
  return `${hh < 10 ? `0${hh}` : hh}:${mm < 10 ? `0${mm}` : mm}`;
};
