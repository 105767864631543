import React, {FC, useState} from 'react';
import { useStyles } from '../StudentsPageStyle';
import { Button, TextField, Typography } from '@mui/material';
import { teacherAPI } from '../../../API/API';
import { CoursesType, TeacherInfoType } from '../../../ducs/teacher/actions';
import { AppStateType } from '../../../ducs';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

type AddStudentPageType = {
  teacherData: TeacherInfoType;
    currentCourse: CoursesType;
};

const AddStudentPage: FC<AddStudentPageType> = (props) => {
  const [studentEmail, setStudentEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const history = useHistory();

  const { teacherData, currentCourse } = props;

  const classes = useStyles();

  const onAddStudent = () => {
    setSubmitting(true);
    teacherAPI
      .createStudent(teacherData.id, { email: studentEmail, course: currentCourse })
      .then((res) => {
        res.id && history.push(`/students/${res.id}`);
      }).finally(() => setSubmitting(false));
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.addStudentText}>
        <Typography>Чтобы добавить ученика, введите его адрес электронной почты:</Typography>
      </div>
      <TextField
        name="studentEmail"
        variant="outlined"
        value={studentEmail}
        sx={{
            margin: '15px 0 0 30px',
        }}
        onChange={(e) => setStudentEmail(e.target.value)}
      />
      <div className={classes.addStudentText}>
        <Button
            sx={{
                fontSize: '13px',
                backgroundColor: '#5E81F4',
                color: 'white',
                '&:hover': {
                    backgroundColor: '#5E81F4'}}}
          onClick={onAddStudent}
          disabled={submitting || !studentEmail}
        >
          Добавить ученика
        </Button>
      </div>
    </div>
  );
};

type MapStateToPropsType = {
  teacherData: TeacherInfoType;
    currentCourse: CoursesType;
};

let MapStateToProps = (state: AppStateType) => ({
  teacherData: state.teacherData.teacherData,
    currentCourse: state.teacherData.currentCourse,
});

export default connect<MapStateToPropsType, null, {}, AppStateType>(MapStateToProps)(
  AddStudentPage,
);
