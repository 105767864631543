import Keycloak from 'keycloak-js';
import React, { FC } from 'react';
import { teacherAPI } from './API/API';
import { AuthClientError, AuthClientEvent } from '@react-keycloak/core/lib/types';
import { store } from './store/redux-store';
import { teacherInfoAction } from "./ducs/teacher/actions";

export const AUTH_ENDPOINT = process.env.AUTH_BASE_URL || 'https://auth.devinfra.ru/';
export const REALM_NAME = process.env.REALM_NAME || 'protonmath';
const CLIENT_ID = process.env.AUTH_CLIENT_ID || 'teacher.protonmath.ru';

export const authClient = new Keycloak({
  url: AUTH_ENDPOINT,
  realm: REALM_NAME,
  clientId: CLIENT_ID,
});
export const initOptions = {
  pkceMethod: 'S256',
  checkLoginIframe: false,
};

export const handleOnEvent = async (event: AuthClientEvent, error: AuthClientError) => {
  if (event === 'onAuthSuccess') {
    if (authClient.authenticated) {
      let currentTeacherInfo = await teacherAPI.getCurrentTeacherInfo();
      if (Object.keys(currentTeacherInfo).length === 0) {
        currentTeacherInfo = await teacherAPI.createTeacher();
      }
      store.dispatch(teacherInfoAction(currentTeacherInfo));
      const course = JSON.parse(localStorage.getItem('currentCourse'));
      if (!course?.value && currentTeacherInfo?.courses.length) {
        localStorage.setItem('currentCourse', JSON.stringify(currentTeacherInfo.courses[0]));
      }
    }
  }
};

export const getAuthUserId = (): string => {
  return authClient.tokenParsed.sub;
};

export const getAuthUserEmail = (): string => {
  return authClient.tokenParsed.email;
};

export const getToken = (): string => {
  return authClient.token;
};

export const isAuthenticated = (): Boolean => {
  return authClient.authenticated;
};

export const loadingComponent: FC = (): JSX.Element => {
  return <h1>Auth processing</h1>;
};
