import { createStyles, makeStyles } from '@mui/styles';

export const muiStyles = {
    addLessonButton: {
        fontSize: '13px',
        backgroundColor: '#5E81F4',
        color: 'white',
        '&:hover': {
            backgroundColor: '#5E81F4',
        },
    },
}

export const useStyles = makeStyles(() =>
  createStyles({
    tabsRoot: {
      flexGrow: 1,
      backgroundColor: '#F5F5FB',
      padding: '20px',
    },
    theoryRoot: {
      padding: '10px 0 0 0',
    },
    tableCellRoot: {
      paddingRight: '30px',
    },
    wrapper1: {
      display: 'flex',
      flexDirection: 'column',
      width: '1050px',
      height: '720px',
      backgroundColor: '#F5F5FB',
    },
    wrapper2: {
      display: 'flex',
    },
    middleWrapper: {
      width: '900px',
      backgroundColor: '#F5F5FB',
    },
    textArea: {
      width: '880px',
      height: '820px',
      backgroundColor: 'white',
      borderRadius: '10px',
      margin: '10px',
      padding: '10px 10px 10px 20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    title: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'space-between',
      '& Button': {
        backgroundColor: '#5E81F4',
        color: 'white',
      },
    },
    videoArea: {
      width: '880px',
      height: '380px',
      backgroundColor: 'white',
      borderRadius: '10px',
      margin: '10px',
      padding: '10px',
      position: 'relative',
    },
    video: {
      position: 'absolute',
      left: '120px',
    },
    rightSideWrap: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '150px',
      backgroundColor: 'white',
    },
    fixedMenu: {
      position: 'fixed',
      bottom: '300px',
      '& a': {
        textDecoration: 'none',
        color: '#A2A2C2',
        fontWeight: 'normal',
        fontSize: '16px',
      },
      '& div': {
        margin: '15px',
      },
    },
    cardRoot: {
      width: '330px',
      height: '300px',
      margin: '15px',
    },
    link: {
      textDecoration: 'none',
      color: 'black',
    },
    addLessonButtonWrap: {
      display: 'flex',
      justifyContent: 'end',
      marginRight: '20px',
    },
    linkStyle: {
      textDecoration: 'none',
    },
  }),
);
