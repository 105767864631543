import React, {FC, useState} from 'react';
import Box from '@mui/material/Box';
import { useStyles } from './UpBarStyle';
import { NavLink } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import {
  Avatar,
  ClickAwayListener,
  Divider,
  Grow,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import Logout from '@mui/icons-material/Logout';
import Delete from '@mui/icons-material/Delete';
import {TeacherInfoType} from '../../ducs/teacher/actions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {useSelector} from "react-redux";
import {AppStateType} from "../../ducs";

type Props = {
  teacherData: TeacherInfoType;
};

export const UpBar: FC<Props> = (props) => {
  const classes = useStyles();
  const { keycloak } = useKeycloak();
  const date = new Date();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const currentCourse = useSelector((state: AppStateType) => state.teacherData.currentCourse);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setAnchorEl(null);
    }
  };

  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  };

  return (
    <div className={classes.barWrapper}>
      <div className={classes.upBar}>
        <div className={classes.date}>
          {/*TODO: fix options */}
          {date.toLocaleDateString('ru', options as any)}
        </div>
      </div>
      <Box className={classes.userWrapper}>
        <div
          className={classes.avatarWrap}
          onClick={handleClick}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
        >
          <Avatar sx={{
            display: 'flex',
            height: '56px',
            width: '56px',
          }}>T</Avatar>
          <div className={classes.personalInfoWrapper}>
            <div className={classes.textWrapper}>
            <Typography sx={{
                  display: 'flex',
                  fontSize: '12px',
                  lineHeight: '15px',
                  fontWeight: '600',
                  color: '#828282',
                  margin: '10px 0 8px 10px',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
            }}>
              {props.teacherData.firstName + ' ' + props.teacherData.lastName}
            </Typography>
              {<Typography sx={{
                display: 'flex',
                fontSize: '12px',
                lineHeight: '15px',
                fontWeight: '500',
                color: currentCourse?.value ? '#BDBDBD' : "red",
                textOverflow: 'ellipsis',
                marginLeft: '10px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}>
                {currentCourse?.value ? currentCourse.value.toUpperCase() : "Выберите предмет"}
              </Typography>}
            </div>
            {open ? (
              <ExpandLessIcon className={classes.icon} />
            ) : (
              <ExpandMoreIcon className={classes.icon} />
            )}
          </div>
        </div>
        <Popper
          className={classes.popperWrap}
          open={open}
          anchorEl={anchorEl}
          transition
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper className={classes.paper}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <MenuItem onClick={handleClose}>
                      <NavLink className={classes.personalInfo} to={'/personalAccount'}>
                        <Avatar /> <span className={classes.personalInfoText}>Личный кабинет</span>
                      </NavLink>
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => alert('deleted!!!')}>
                      <ListItemIcon>
                        <Delete fontSize="small" />
                      </ListItemIcon>
                      Удалить учетную запись
                    </MenuItem>
                    <MenuItem onClick={() => keycloak.logout()}>
                      <ListItemIcon>
                        <Logout fontSize="small" />
                      </ListItemIcon>
                      Выйти
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </div>
  );
};

export default UpBar;
