import React, { FC } from 'react';
import { useStyles } from '../StudentsPageStyle';
import { Avatar, Button, Paper, TextareaAutosize, Divider } from '@mui/material';
import TabPanel from 'src/Components/TabPanel';

type StudentCardType = any;

const StudentCard: FC<StudentCardType> = (props) => {
  const { chosenStudent, isEdit, setEdit } = props;

  const classes = useStyles();

  return (
    <Paper sx={{
      height: '600px',
    }}>
      <div className={classes.studentCardWrapper}>
        <div className={classes.studentCardAvatarWrapper}>
          <Avatar
            alt="avatar"
            src="https://mir-s3-cdn-cf.behance.net/project_modules/disp/ea7a3c32163929.567197ac70bda.png"
          />
          <div
            className={classes.avatarName}
          >{`${chosenStudent?.firstName} ${chosenStudent?.lastName}`}</div>
        </div>
        <Divider />
        <div className={classes.studentCardContentWrapper}>
          <div className={classes.studentCardColumnWrapper}>
            <div>
              <span className={classes.itemName}>Страна</span>
              <span>{chosenStudent?.country}</span>
            </div>
            <div>
              <span className={classes.itemName}>Город</span>
              <span>{chosenStudent?.city}</span>
            </div>
            <div>
              <span className={classes.itemName}>Почта</span>
              <span>{chosenStudent?.email}</span>
            </div>
            <div>
              <span className={classes.itemName}>Телефон</span>
              <span>{chosenStudent?.phone}</span>
            </div>
            <div>
              <span className={classes.itemName}>Класс</span>
              <span>{chosenStudent?.level}</span>
            </div>
            <div>
              <span className={classes.itemName}>Баланс</span>
              <span>{chosenStudent?.balance}</span>
            </div>
            <div>
              <span className={classes.itemName}>Расписание</span>
              <span>какое-то расписание</span>
            </div>
          </div>
          <div className={classes.studentCardColumnWrapper}>
            <div>
              <span className={classes.itemName}>Имя</span>
              <span>{chosenStudent?.parent?.firstName}</span>
            </div>
            <div>
              <span className={classes.itemName}>Отчество</span>
              <span>{chosenStudent?.parent?.middleName}</span>
            </div>
            <div>
              <span className={classes.itemName}>Почта</span>
              <span>{chosenStudent?.parent?.email}</span>
            </div>
            <div>
              <span className={classes.itemName}>Телефон</span>
              <span>{chosenStudent?.parent?.phone}</span>
            </div>
            <div>
              <span className={classes.itemName}>Статус</span>
              <span>{chosenStudent?.parent?.relationName}</span>
            </div>
            <div className={classes.itemAimName}>Цель занятий</div>
            <div className={classes.textArea}>
              <TextareaAutosize />
            </div>
          </div>
        </div>
        <div className={classes.studentCardButtonWrapper}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              color: '#FFF',
              backgroundColor: '#5E81F4',
              width: '200px',
            }}
            onClick={() => setEdit(!isEdit)}
          >
            Редактировать
          </Button>
        </div>
      </div>
      <TabPanel
        labeles={['Учебный план', 'Пройденный материал', 'Аналитика']}
        content={[]}
        centered
        abbBarColor="transparent"
        indicatorColor="primary"
      />
    </Paper>
  );
};

export default StudentCard;
