import { LESSONS } from './actions-type';
import { getTheoryAction, getTheoryIdAction } from './actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { lessonsAPI, postTheoryType } from '../../API/API';

type InitialState = {
  lessonsDictionary: object[];
  lessonsTheory: postTheoryType[];
  lessonsTheoryId: postTheoryType;
};

const initialState: InitialState = {
  lessonsDictionary: [],
  lessonsTheory: [],
  lessonsTheoryId: undefined,
};
// TODO: action type?
export const lessonsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LESSONS.GET_THEORY:
      return {
        ...state,
        lessonsTheory: action.data,
      };
    case LESSONS.GET_THEORY_ID:
      return {
        ...state,
        lessonsTheoryId: action.data,
      };
    default:
      return state;
  }
};

function* postTheory(action: {
  data: postTheoryType;
  type: typeof LESSONS.TRIGGER_POST_THEORY;
}): any {
  try {
    yield call(() => {
      return lessonsAPI.postTheory(action.data);
    });
    const data = yield call(() => {
      return lessonsAPI.getTheoryList();
    });
    yield put(getTheoryAction(data));
  } catch (error) {
    console.log('Sending error');
  }
}

function* getTheory(): any {
  try {
    const data = yield call(() => {
      return lessonsAPI.getTheoryList();
    });
    yield put(getTheoryAction(data));
  } catch (error) {
    console.log('Sending error');
  }
}

function* getTheoryId(action: { id: number; type: typeof LESSONS.TRIGGER_GET_THEORY_ID }): any {
  try {
    const data = yield call(() => {
      return lessonsAPI.getTheory(action.id);
    });
    yield put(getTheoryIdAction(data));
  } catch (error) {
    console.log('Sending error');
  }
}

function* deleteTheory(action: { id: number; type: typeof LESSONS.TRIGGER_DELETE_THEORY }): any {
  try {
    yield call(() => {
      return lessonsAPI.deleteTheory(action.id);
    });
    const data = yield call(() => {
      return lessonsAPI.getTheoryList();
    });
    yield put(getTheoryAction(data));
  } catch (error) {
    console.log('Delete error');
  }
}
// Пока используется lessonsAPI.postImage, а не saga
// function* postImage(action: { data: postImageType, type: typeof LESSONS.TRIGGER_POST_IMAGE }): any {
//   try {
//     yield call(() => {
//       return lessonsAPI.postImage(action.data);
//     });
//   } catch (error) {
//     console.log("Sending error");
//   }
// }

export function* watchLessonsSaga() {
  yield takeEvery(LESSONS.TRIGGER_POST_THEORY, postTheory);
  yield takeEvery(LESSONS.TRIGGER_GET_THEORY, getTheory);
  yield takeEvery(LESSONS.TRIGGER_GET_THEORY_ID, getTheoryId);
  yield takeEvery(LESSONS.TRIGGER_DELETE_THEORY, deleteTheory);
  // yield takeEvery(LESSONS.TRIGGER_POST_IMAGE, postImage);
}
