export const TEACHER = {
  TRIGGER_GET_INFO: 'TRIGGER_GET_INFO',
  GET_INFO: 'GET_INFO',
  TRIGGER_GET_STUDENTS: 'TRIGGER_GET_STUDENTS',
  GET_STUDENTS: 'GET_STUDENTS',
  TRIGGER_GET_FUTURE_LESSONS: 'TRIGGER_GET_FUTURE_LESSONS',
  GET_FUTURE_LESSONS: 'GET_FUTURE_LESSONS',
  TRIGGER_GET_LESSONS_STATUSES: 'TRIGGER_GET_LESSONS_STATUSES',
  TRIGGER_CHANGE_LESSON_STATUS: 'TRIGGER_CHANGE_LESSON_STATUS',
  GET_LESSONS_STATUSES: 'GET_LESSONS_STATUSES',
  GET_LESSONS_LIST: 'GET_LESSONS_LIST',
  GET_COURSES: 'GET_COURSES',
  TRIGGER_GET_COURSES: 'TRIGGER_GET_COURSES',
  SET_COURSES: 'SET_COURSES',
  TRIGGER_SET_COURSES: 'TRIGGER_SET_COURSES',
  TRIGGER_DELETE_COURSES: 'TRIGGER_DELETE_COURSES',
  TRIGGER_GET_LESSONS_STATS: 'TRIGGER_GET_LESSONS_STATS',
  GET_LESSONS_STATS: 'GET_LESSONS_STATS',
  TRIGGER_UPDATE_INFO: 'TRIGGER_UPDATE_INFO',
  TRIGGER_STUDENT_UPDATE_INFO: 'TRIGGER_STUDENT_UPDATE_INFO',
  UPDATE_INFO: 'UPDATE_INFO',
  TRIGGER_ADD_BALANCE: 'TRIGGER_ADD_BALANCE',
  SET_CURRENT_COURSE: 'SET_CURRENT_COURSE',
} as const;
