import { TEACHER } from './actions-type';

export type TeacherInfoType = {
  id: number;
  firstName: string;
  lastName: string;
  middleName: string;
  country: string;
  city: string;
  email: string;
  courses: CoursesType[];
};

export type StudentsType = {
  id: number;
  email: 'string';
  firstName: 'string';
  lastName: 'string';
  middleName: 'string';
  phone: 'string';
  notes: 'string';
  country: 'string';
  city: 'string';
  balance: number;
  parent: {
    firstName: 'string';
    lastName: 'string';
    middleName: 'string';
    relationName: 'string';
    email: 'string';
    phone: 'string';
  };
  course: {
    owner: 'string';
    value: 'string';
  };
};

export type FutureLessonsType = {
  fromPlannedDateTime: string;
  toPlannedDateTime: string;
  teacherIds: number[];
};

export type LessonsStatusesType = {
  toPlannedDateTime: string;
  teacherIds: number[];
};

type CardsType = {
  id: number;
  name: string;
  payload: string;
};

type SubTopicsType = {
  id: number;
  name: string;
  author: string;
  cards: CardsType[];
};

type StudentType = {
  id: number;
  name: string;
  level: string;
};

export type TeacherStatsType = {
  id: number;
  from: string;
  to: string;
};

export type LessonsListType = {
  id: number;
  plannedDateTime: string;
  student: StudentType;
  teacher: TeacherInfoType;
  status: string;
  subject: string;
  lessonType: string;
  subtopics: SubTopicsType[];
}[];

export type LessonsStatsType = {
  finished: number;
  planned: number;
  canceled: number;
  missed: number;
};

export type CoursesType = {
  owner: string;
  value: string;
};

export type TeacherChangeLessonType = {
  tId: number;
  lId: number;
  status: string;
  setDisabled: any;
};

export type TeacherInfoUpdateType = {
  id: number;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  country?: string;
  city?: string;
  setSubmitting?: any;
  setSuccess?: any;
  setError?: any;
  courses?: [];
};

export type TeacherStudentInfoUpdateType = {
  phone: string;
  parent: {
    firstName: string;
    lastName: string;
    middleName: string;
    relationName: string;
    email: string;
    phone: string;
  };
};

export const teacherInfoAction = (data: TeacherInfoType) => ({
  type: TEACHER.GET_INFO,
  data,
});

export const getStudentsAction = (data: StudentsType) => ({
  type: TEACHER.GET_STUDENTS,
  data,
});

export const triggerGetStudentsAction = (id: number, course: { teacherCourse: CoursesType }) => ({
  type: TEACHER.TRIGGER_GET_STUDENTS,
  id,
  course,
});

export const triggerTeacherFutureLessonsAction = (data: FutureLessonsType) => ({
  type: TEACHER.TRIGGER_GET_FUTURE_LESSONS,
  data,
});

export const teacherFutureLessonsAction = (data: FutureLessonsType) => ({
  type: TEACHER.GET_FUTURE_LESSONS,
  data,
});

export const triggerTeacherLessonsStatusesAction = (data: LessonsStatusesType) => ({
  type: TEACHER.TRIGGER_GET_LESSONS_STATUSES,
  data,
});

export const triggerTeacherChangeLessonAction = (data: TeacherChangeLessonType) => ({
  type: TEACHER.TRIGGER_CHANGE_LESSON_STATUS,
  data,
});

export const teacherLessonsStatusesAction = (data: LessonsStatusesType) => ({
  type: TEACHER.GET_LESSONS_STATUSES,
  data,
});

export const teacherLessonsListAction = (data: LessonsListType) => ({
  type: TEACHER.GET_LESSONS_LIST,
  data,
});

export const triggerGetCoursesAction = (id: number) => ({
  type: TEACHER.TRIGGER_GET_COURSES,
  id,
});

export const getCoursesAction = (data: CoursesType[]) => ({
  type: TEACHER.GET_COURSES,
  data,
});

export const triggerSetCoursesAction = (id: number, data: CoursesType) => ({
  type: TEACHER.TRIGGER_SET_COURSES,
  id,
  data,
});

export const triggerDeleteCoursesAction = (id: number, data: CoursesType) => ({
  type: TEACHER.TRIGGER_DELETE_COURSES,
  id,
  data,
});

export const teacherLessonsStatsAction = (data: LessonsStatsType) => ({
  type: TEACHER.GET_LESSONS_STATS,
  data,
});

export const triggerTeacherLessonsStatsAction = (data: TeacherStatsType) => ({
  type: TEACHER.TRIGGER_GET_LESSONS_STATS,
  data,
});

export const teacherInfoUpdateAction = (data: TeacherInfoUpdateType) => ({
  type: TEACHER.UPDATE_INFO,
  data,
});

export const teacherSetCourseAction = (currentCourse: CoursesType) => ({
  type: TEACHER.SET_CURRENT_COURSE,
  currentCourse,
});

export const triggerTeacherInfoUpdateAction = (data: TeacherInfoUpdateType) => ({
  type: TEACHER.TRIGGER_UPDATE_INFO,
  data,
});

export const triggerTeacherStudentInfoUpdateAction = (
  data: TeacherStudentInfoUpdateType,
  id: number,
  cardId: number,
  course: { teacherCourse: CoursesType },
) => ({
  type: TEACHER.TRIGGER_STUDENT_UPDATE_INFO,
  data,
  id,
  cardId,
  course,
});

export const triggerAddBalanceAction = (
  id: number,
  studentId: number,
  count: number,
  course: CoursesType,
) => ({
  type: TEACHER.TRIGGER_ADD_BALANCE,
  id,
  studentId,
  count,
  course,
});
