import React, { FC, useEffect, useMemo, useState } from 'react';
import {muiStyles, useStyles} from '../CreateLessonStyle';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Button, Chip, TextField, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';

type Props = {
  attachedFiles: any[];
  attachedLinks: string[];
  attachedLinksVideo: string[];
  setAttachedFiles(p: (attachedFiles: any[]) => any[]): void;
  setAttachedLinks(p: (attachedLinks: string[]) => string[]): void;
  setAttachedLinksVideo(p: (attachedLinks: string[]) => string[]): void;
};

type fileType = {
  path: string;
  lastModified: number;
  lastModifiedDate: object;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
};

const focusedStyle = {
  borderColor: '#5E81F4',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const accept = {
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/pdf': ['.pdf'],
};

const Step3: FC<Props> = (props) => {
  const classes = useStyles();
  const {
    setAttachedFiles,
    attachedLinks,
    setAttachedLinks,
    attachedLinksVideo,
    setAttachedLinksVideo,
  } = props;
  const [currentLink, setCurrentLink] = useState('');
  const [currentLinkVideo, setCurrentLinkVideo] = useState('');
  const { acceptedFiles, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept,
      maxSize: 10000000,
    });

  useEffect(
    () =>
      setAttachedFiles((attachedFiles) => {
        const uniqueValues: fileType[] = [];
        // @ts-ignore
        acceptedFiles.map((it: fileType) => {
          const duplicates = attachedFiles.filter((item) => item.path === it.path);
          if (!duplicates.length) uniqueValues.push(it);
        });
        return [...new Set([...attachedFiles, ...uniqueValues])];
      }),
    [acceptedFiles],
  );

  // const style = useMemo(
  //   () => ({
  //     ...(isFocused ? focusedStyle : {}),
  //     ...(isDragAccept ? acceptStyle : {}),
  //     ...(isDragReject ? rejectStyle : {}),
  //   }),
  //   [isFocused, isDragAccept, isDragReject],
  // );
  // const handleFileDelete = (path: string) => () => {
  //   setAttachedFiles((attachedFiles: any[]) => attachedFiles.filter((it) => it.path !== path));
  // };
  const handleLinkDelete =
    (link: string, setAttachedLinks: (p: (attachedLinks: string[]) => string[]) => void) => () => {
      setAttachedLinks((attachedLinks: string[]) => attachedLinks.filter((it) => it !== link));
    };
  const handleAddLink =
    (
      link: string,
      setAttachedLinks: (p: (attachedLinks: string[]) => string[]) => void,
      setCurrentLink: (p: string) => void,
    ) =>
    () => {
      setAttachedLinks((attachedLinks: string[]) =>
        link.length > 0 ? [...attachedLinks, link] : [...attachedLinks],
      );
      setCurrentLink('');
    };

  // const getFileTypes = () => {
  //   const typeNames: string[] = [];
  //   Object.values(accept).map((it: any) => {
  //     if (Array.isArray(it)) it.forEach((name) => typeNames.push(name));
  //     else typeNames.push(it);
  //   });
  //   return typeNames.map((it, idx) => (idx !== typeNames.length - 1 ? `${it}, ` : it));
  // };

  const getInputField = (
    label: string,
    bottomLabel: string,
    currentLink: string,
    setCurrentLink: (p: string) => void,
    handleAddLink: any,
    handleLinkDelete: any,
    attachedLinks: string[],
    setAttachedLinks: (p: (attachedLinks: string[]) => string[]) => void,
  ) => (
    <div className={classes.inputFieldWrapper}>
      <div className={classes.inputWrapper}>
        <TextField
          size="small"
          value={currentLink}
          onChange={(e) => setCurrentLink(e.currentTarget.value)}
          classes={{ root: classes.textField }}
          label={label}
          variant="outlined"
        />
        <Button
          sx={muiStyles.addButton}
          onClick={handleAddLink(currentLink, setAttachedLinks, setCurrentLink)}
        >
          Добавить
        </Button>
      </div>
      {attachedLinks.length ? (
        <Typography sx={muiStyles.attachName}>{bottomLabel}:</Typography>
      ) : null}
      {attachedLinks.map((it) => (
        <div className={classes.chipWrapper} key={it}>
          <Chip
            variant="outlined"
            size="small"
            label={it}
            onDelete={handleLinkDelete(it, setAttachedLinks)}
          />
        </div>
      ))}
    </div>
  );

  return (
      <div className={`${classes.stepContentWrapper} ${classes.alignCenter}`}>
      <Typography variant="h6">Ссылки и документы</Typography>
      <div>
        {getInputField(
          'Вставьте ссылку на видео',
          'Ссылки на видео',
          currentLinkVideo,
          setCurrentLinkVideo,
          handleAddLink,
          handleLinkDelete,
          attachedLinksVideo,
          setAttachedLinksVideo,
        )}
        {getInputField(
          'Вставьте ссылку на документ',
          'Ссылки',
          currentLink,
          setCurrentLink,
          handleAddLink,
          handleLinkDelete,
          attachedLinks,
          setAttachedLinks,
        )}
        {/*<div>*/}
        {/*    <div {...getRootProps({className: classes.dropZone, style})}>*/}
        {/*        <input {...getInputProps()} />*/}
        {/*        <div className={classes.attachmentWrapper}>*/}
        {/*            <div className={classes.attachmentName}>Перетащите файл или кликните для выбора файлов</div>*/}
        {/*            <div className={classes.attachmentName}>Максимальный размер: 10МБ</div>*/}
        {/*            <div className={classes.attachmentName}>Файл формата ({getFileTypes()})</div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*    {attachedFiles.length ? <Typography className={classes.attachName}>Файлы:</Typography> : null}*/}
        {/*    {*/}
        {/*        attachedFiles.map(it => <div className={classes.chipWrapper} key={it.path}>*/}
        {/*            <Chip*/}
        {/*                variant="outlined"*/}
        {/*                size="small"*/}
        {/*                label={it.path}*/}
        {/*                onDelete={handleFileDelete(it.path)}*/}
        {/*            />*/}
        {/*        </div>)*/}
        {/*    }*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default Step3;
