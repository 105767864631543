// @ts-nocheck
import * as React from 'react';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  AppointmentModel,
  EditingState,
  IntegratedEditing,
  ViewState,
} from '@devexpress/dx-react-scheduler';
import {
  AppointmentForm,
  Appointments,
  DateNavigator,
  DayView,
  Scheduler,
  Toolbar,
  ViewSwitcher,
  WeekView,
  AppointmentTooltip,
  ConfirmationDialog,
} from '@devexpress/dx-react-scheduler-material-ui';
import EditIcon from '@mui/icons-material/Edit';
import { muiStyles, useStyles } from './ScheduleStyle';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, TextField, Paper } from '@mui/material';
import { MatchParams } from '../../types/router';
import AddScheduleLesson from './Pages/addScheduleLesson';
import { connect } from 'react-redux';
import { AppStateType } from '../../ducs';
import { calendarDataGet } from '../../API/API';
import {
  updateLessonScheduleAction,
  triggerGetLessonSchedule,
  deleteLessonScheduleAction,
} from '../../ducs/calendar/actions';
import {
  CoursesType,
  StudentsType,
  TeacherInfoType,
  triggerGetStudentsAction,
} from '../../ducs/teacher/actions';
import { format } from 'date-fns';
import moment from 'moment';
import { DayPicker } from 'react-day-picker';
import { ru } from 'date-fns/locale';
import styles from 'react-day-picker/dist/style.module.css';

type Props = {
  teacherData: TeacherInfoType;
  appointmentsData: (AppointmentModel & { appointmentId: number })[];
  students: StudentsType[];
  currentCourse: CoursesType;
  dispatch: any;
} & RouteComponentProps<MatchParams> & {};

const CommandButton = ({ id, ...restProps }) => {
  if (id === 'deleteButton') {
    return <></>;
  }
  return <AppointmentForm.CommandButton id={id} {...restProps} />;
};

const TextEditor = ({ type, ...restProps }) => {
  if (type === 'titleTextEditor') {
    return <AppointmentForm.TextEditor type={type} {...restProps} readOnly />;
  }
  return <></>;
};

const DateEditor = (props) => {
  const { value, onValueChange } = props;
  const [day, setDay] = useState<Date>(value);
  const [startTime, setStartTime] = useState<string>(
    `${value.getHours()}:${
      value.getMinutes() < 10 ? `0${value.getMinutes()}` : value.getMinutes()
    }`,
  );
  const [endTime, setEndTime] = useState<string>(
    `${value.getHours()}:${
      value.getMinutes() < 10 ? `0${value.getMinutes()}` : value.getMinutes()
    }`,
  );
  const classes = useStyles();
  const dayPickerClasses = { ...styles, day_selected: classes.daySelected };

  const onChangeTime = (time, setTime) => {
    const date = new Date(day);
    date.setHours(+time.slice(0, 2));
    date.setMinutes(+time.slice(3));
    setTime(time);
    onValueChange(date);
  };

  const onChangeDate = (date) => {
    date.setHours(+startTime.slice(0, 2));
    date.setMinutes(+startTime.slice(3));
    setDay(date);
    onValueChange(date);
  };

  const disabledCallback = (date: Date) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);
    return date < currentDate;
  };

  return (
    <div className={classes.dayPickerChange}>
      <div className="day-picker-wrapper">
        <DayPicker
          classNames={dayPickerClasses}
          selected={day}
          onSelect={onChangeDate}
          mode="single"
          locale={ru}
          disabled={(date) => disabledCallback(date)}
        />
      </div>
      <div className="TextField-start-wrapper">
        <TextField
          variant="standard"
          id="time-start"
          label="Начало урока"
          type="time"
          value={startTime}
          onChange={(e) => onChangeTime(e.currentTarget.value, setStartTime)}
          sx={{
            display: 'flex',
            width: '200px',
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300,
          }}
        />
      </div>
      <div className="TextField-end-wrapper">
        <TextField
          variant="standard"
          id="time-end"
          label="Конец урока"
          type="time"
          value={endTime}
          onChange={(e) => onChangeTime(e.currentTarget.value, setEndTime)}
          sx={{
            display: 'flex',
            width: '200px',
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300,
          }}
        />
      </div>
    </div>
  );
};

const LabelComponent = ({ text, ...restProps }) => {
  if (text === 'Details') {
    return <AppointmentForm.Label text="Перенос урока" {...restProps} />;
  }
  return <></>;
};

const TimeTableCell = ({ onDoubleClick, ...restProps }) => (
  <WeekView.TimeTableCell {...restProps} onDoubleClick={undefined} />
);

const HeaderTooltip = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const refTooltip = useRef(null);
  const classes = useStyles();
  const screenWidth = refTooltip.current?.offsetParent?.offsetParent?.clientWidth;
  const offsetLeft = refTooltip.current?.offsetParent?.offsetLeft;
  const onEditClick = () => {
    props.setShowTooltip(false);
    props.onOpenButtonClick();
    window.scrollTo(0, 0);
  };
  const onCancelClick = () => {
    props.commitChanges({ canceled: props.appointmentData.id });
  };
  return (
    <div className={classes.headerTooltipWrapper} ref={refTooltip}>
      <EditIcon
        className={classes.editButton}
        onClick={() => setShowMenu((prevState) => setShowMenu(!prevState))}
      />
      {showMenu ? (
        <Paper
          sx={muiStyles.menuTooltip}
          elevation={3}
          style={screenWidth - offsetLeft < 535 ? { right: 0, top: '-110px' } : undefined}
        >
          <div className={classes.menuTooltipItem} onClick={onCancelClick}>
            Отменить разово
          </div>
          <div className={classes.menuTooltipItem} onClick={props.onDeleteButtonClick}>
            Удалить навсегда
          </div>
          <div className={classes.menuTooltipItem} onClick={onEditClick}>
            Перенести
          </div>
        </Paper>
      ) : null}
    </div>
  );
};

const Appointment = ({
  children,
  style,
  data,
  onClick,
  setShowTooltip,
  setAppointmentMeta,
  showTooltip,
  students,
  ...restProps
}) => {
  const isPastDate = data.startDate < new Date();
  const balance = students.find((student) => data.studentId === student.id)?.balance;
  const handleClick = (e) => {
    setShowTooltip(!showTooltip);
    setAppointmentMeta({ target: e.target.parentElement.parentElement, data });
  };

  return (
    <Appointments.Appointment
      {...restProps}
      data={data}
      style={{
        ...style,
        backgroundColor: !isPastDate && (balance > 1 ? undefined : 'red'),
      }}
    >
      <div
        style={{ position: 'absolute', zIndex: '10', width: '100%', height: '100%' }}
        onClick={isPastDate ? undefined : handleClick}
      />
      {children}
      {!isPastDate && (
        <div style={{ paddingLeft: '10px', color: 'white' }}>Баланс уроков: {balance}</div>
      )}
    </Appointments.Appointment>
  );
};

const SchedulePage: FC<Props> = (props) => {
  const classes = useStyles();
  const [showTooltip, setShowTooltip] = useState(undefined);
  const [appointmentMeta, setAppointmentMeta] = useState(undefined);
  const { teacherData, appointmentsData, dispatch, students, match, currentCourse } = props;
  const params = match.params.addLesson;

  useEffect(() => {
    currentCourse &&
      dispatch(triggerGetStudentsAction(teacherData.id, { teacherCourse: currentCourse }));
  }, [currentCourse]);

  useEffect(() => {
    dispatch(triggerGetLessonSchedule(teacherData.id, students));
  }, [teacherData.id, students, params]);

  const commitChanges = ({
    deleted,
    changed,
    canceled,
  }: {
    deleted?: string;
    changed?: { [key: string]: any };
    canceled?: string;
  }) => {
    const changedKey = changed && Object.keys(changed)[0];
    const lessonId = canceled || changedKey || deleted;
    const appointmentId = appointmentsData?.find((it) => it.id === lessonId)?.appointmentId;
    const exceptionDateRaw = appointmentsData?.find((it) => it.id === lessonId)?.startDate;
    const exceptionDate = moment(exceptionDateRaw).format('YYYY-MM-DDTHH:mm:ss.sssZ');
    if (changed) {
      let startDate = changed[changedKey].startDate;
      let endDate = changed[changedKey].endDate;
      if (!startDate) {
        startDate = appointmentsData.find((data) => data.id === lessonId).startDate;
      }
      if (!endDate) {
        endDate = appointmentsData.find((data) => data.id === lessonId).endDate;
      }
      endDate.setMonth(startDate.getMonth());
      endDate.setDate(startDate.getDate());
      const newDateTime = moment(startDate).format('YYYY-MM-DDTHH:mm:ss.sssZ');
      const durationMinutes = (endDate.getTime() - startDate.getTime()) / 60000;
      dispatch(
        updateLessonScheduleAction(
          appointmentId,
          teacherData.id,
          { exceptionDate, newCalendarDate: { newDateTime, durationMinutes } },
          students,
        ),
      );
    }
    if (canceled) {
      setShowTooltip(false);
      dispatch(
        updateLessonScheduleAction(appointmentId, teacherData.id, { exceptionDate }, students),
      );
    }
    if (deleted) {
      dispatch(deleteLessonScheduleAction(appointmentId, teacherData.id, students));
    }
  };

  const myAppointment = useCallback(
    (props) => (
      <Appointment
        {...props}
        setShowTooltip={setShowTooltip}
        showTooltip={showTooltip}
        students={students}
        setAppointmentMeta={setAppointmentMeta}
      />
    ),
    [setShowTooltip, showTooltip, students, setAppointmentMeta],
  );

  const myHeaderTooltip = useCallback(
    (props) => (
      <HeaderTooltip {...props} setShowTooltip={setShowTooltip} commitChanges={commitChanges} />
    ),
    [setShowTooltip, commitChanges],
  );

  if (params) return <AddScheduleLesson />;

  return (
    <div className={classes.wrapper}>
      <div className={classes.addLessonButtonWrap}>
        <NavLink to="/schedule/addLesson" className={classes.linkStyle}>
          <Button sx={muiStyles.addLessonButton}>+ Добавить урок в расписание</Button>
        </NavLink>
      </div>
      <Paper>
        <Scheduler data={appointmentsData} height="auto" locale="ru-RU">
          <ViewState
            defaultCurrentDate={format(new Date(), 'yyyy-MM-dd')}
            defaultCurrentViewName="Week"
          />

          <DayView displayName="День" startDayHour={8} endDayHour={23} />
          <WeekView
            displayName="Неделя"
            startDayHour={8}
            endDayHour={23}
            timeTableCellComponent={TimeTableCell}
          />

          <Toolbar />
          <ViewSwitcher messages="ru-RU" />
          <DateNavigator />
          <EditingState onCommitChanges={commitChanges} />
          <IntegratedEditing />
          <ConfirmationDialog
            messages={{
              deleteButton: 'Удалить',
              discardButton: 'Выйти',
              cancelButton: 'Отмена',
              confirmDeleteMessage: 'Вы действительно хотите удалить запись?',
              confirmCancelMessage: 'Все несохраненные данные будут очищены. Все равно выйти?',
            }}
          />
          <Appointments appointmentComponent={myAppointment} />
          <AppointmentTooltip
            headerComponent={myHeaderTooltip}
            visible={showTooltip}
            onVisibilityChange={() => setShowTooltip((prevState) => !prevState)}
            appointmentMeta={appointmentMeta}
            onAppointmentMetaChange={({ data, target }) => setAppointmentMeta({ data, target })}
          />
          <AppointmentForm
            booleanEditorComponent={() => <></>}
            textEditorComponent={TextEditor}
            dateEditorComponent={DateEditor}
            commandButtonComponent={CommandButton}
            labelComponent={LabelComponent}
            detailsLabel="Детали"
            moreInformationLabel=""
            messages={{ commitCommand: 'Сохранить' }}
          />
        </Scheduler>
      </Paper>
    </div>
  );
};

type MapStateToPropsType = {
  teacherData: TeacherInfoType;
  appointmentsData: calendarDataGet[];
  students: StudentsType[];
  currentCourse: CoursesType;
};

let MapStateToProps = (state: AppStateType) => ({
  teacherData: state.teacherData.teacherData,
  appointmentsData: state.calendarData.appointmentsData,
  students: state.teacherData.students,
  currentCourse: state.teacherData.currentCourse,
});

export default connect<MapStateToPropsType, null, {}, AppStateType>(MapStateToProps)(
  withRouter(SchedulePage),
);
