import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  cardRoot: {
    width: '330px',
    height: '300px',
    margin: '15px',
  },
  link: {
    textDecoration: 'none',
  },
}));
