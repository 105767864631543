import { CodeRounded, ListRounded, ScheduleRounded, RecentActorsRounded, PeopleAltRounded } from '@mui/icons-material';

export const menuItems = [
  {
    label: 'Главная',
    link: '/main',
    icon(classN: string) {
      return <RecentActorsRounded className={classN} />;
    },
  },
  {
    label: 'Ученики',
    link: '/students',
    icon(classN: string) {
      return <PeopleAltRounded className={classN} />;
    },
  },
  {
    label: 'Расписание',
    link: '/schedule',
    icon(classN: string) {
      return <ScheduleRounded className={classN} />;
    },
  },
  {
    label: 'Уроки',
    link: '/lessons',
    icon(classN: string) {
      return <ListRounded className={classN} />;
    },
  },
  // {
  //   label: 'Письменные работы',
  //   link: '/tasks',
  //   icon(classN: string) {
  //     return <MenuBookRoundedIcon className={classN} color="primary" />;
  //   },
  // },
  // {
  //   label: 'Статистика',
  //   link: '/statistics',
  //   icon(classN: string) {
  //     return <EqualizerRoundedIcon className={classN} color="primary" />;
  //   },
  // },
  // {
  //   label: 'Тренинги',
  //   link: '/trainings',
  //   icon(classN: string) {
  //     return <AssignmentTurnedInRoundedIcon className={classN} color="primary" />;
  //   },
  // },
  // {
  //   label: 'Переговорная',
  //   link: '/meetings',
  //   icon(classN: string) {
  //     return <PhoneInTalkRoundedIcon className={classN} color="primary" />;
  //   },
  // },
  {
    label: 'Swagger',
    link: '/swagger',
    icon(classN: string) {
      return <CodeRounded className={classN} />;
    },
  },
];
