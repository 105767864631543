export const LESSONS = {
  TRIGGER_POST_THEORY: 'TRIGGER_POST_THEORY',
  TRIGGER_GET_THEORY: 'TRIGGER_GET_THEORY',
  GET_THEORY: 'GET_THEORY',
  TRIGGER_GET_THEORY_ID: 'TRIGGER_GET_THEORY_ID',
  GET_THEORY_ID: 'GET_THEORY_ID',
  TRIGGER_DELETE_THEORY: 'TRIGGER_DELETE_THEORY',
  // POST_THEORY: "POST_THEORY",
  // TRIGGER_POST_IMAGE: "TRIGGER_POST_IMAGE",
} as const;
