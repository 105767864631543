import React, {FC, useEffect} from 'react';
import { useStyles } from './StudentsPageStyle';
import { Button, Typography, Grid, Paper } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import UserCard from '../../Components/UserCard/UserCard';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import StudentsTab from './components/StudentsTab';
import { MatchParams } from 'src/types/router';
import {
  CoursesType,
  StudentsType,
  TeacherInfoType,
  triggerGetStudentsAction,
} from '../../ducs/teacher/actions';
import { AppStateType } from '../../ducs';
import { connect } from 'react-redux';

type Props = { dispatch?: (action: any) => void } & RouteComponentProps<MatchParams> & {
    teacherData: TeacherInfoType;
    students: StudentsType[];
  currentCourse: CoursesType;
  };

const StudentsPage: FC<Props> = (props) => {
  const { teacherData, students, currentCourse } = props;
  let cardId: string | number = props.match.params.userId;

  useEffect(() => {
    teacherData.id &&
    currentCourse &&
      props.dispatch(
        triggerGetStudentsAction(teacherData.id, { teacherCourse: currentCourse }),
      );
  }, [currentCourse, teacherData.id]);

  const items = [];
  let lastIdx = 0;
  for (let i = 0; i < students.length / 4; i++) {
    items.push([
      students[lastIdx],
      students[lastIdx + 1],
      students[lastIdx + 2],
      students[lastIdx + 3],
    ]);
    lastIdx = lastIdx + 4;
  }

  let chosenStudent = students.filter((it) => +it.id === +cardId)[0];

  const openStudentCardHandler = () => {
    chosenStudent = students.filter((it) => +it.id === +cardId)[0];
  };

  const classes = useStyles();

  if (cardId)
    return (
      <div className={classes.wrapper}>
        <StudentsTab chosenStudent={chosenStudent} cardId={+cardId} {...props} />
      </div>
    );

  return (
    <div className={classes.wrapper}>
      <>
        <div className={classes.textWrapper}>
          <Typography>
            <b>Индивидуальные уроки</b>
          </Typography>
          <NavLink to={'/addStudent'} style={{ textDecoration: 'none' }}>
            <Button sx={{
              fontSize: '13px',
              backgroundColor: '#5E81F4',
              color: 'white',
              '&:hover': {
                backgroundColor: '#5E81F4'}}}>+ Добавить ученика</Button>
          </NavLink>
        </div>
        {!items.length ? (
          <div className={classes.addStudentText}>
            У вас пока нет учеников:) Добавьте ученика при помощи кнопки в правом верхнем углу.{' '}
          </div>
        ) : (
          <Carousel autoPlay={false} indicators={false}>
            {items.map((item) =>
              item.map(
                (it) =>
                  it && (
                    <div className={classes.carouselWrap} key={it.id}>
                      <NavLink
                        to={'/students/' + it.id}
                        onClick={openStudentCardHandler}
                        style={{ textDecoration: 'none' }}
                      >
                        <UserCard
                          imgUrl="https://mir-s3-cdn-cf.behance.net/project_modules/disp/ea7a3c32163929.567197ac70bda.png"
                          key={it.id}
                          name={`${it.firstName} ${it.lastName}`}
                          lessonsNumber={it.balance}
                        />
                      </NavLink>
                    </div>
                  ),
              ),
            )}
          </Carousel>
        )}
        <div className={classes.textWrapper} style={{ marginTop: '40px' }}>
          <Typography>
            <b>Групповые уроки</b>
          </Typography>
          <Button sx={{
            fontSize: '13px',
            backgroundColor: '#5E81F4',
            color: 'white',
            '&:hover': {
              backgroundColor: '#5E81F4'}}} disabled>
            + Добавить группу
          </Button>
        </div>
        <Grid container spacing={0}>
          <Grid item xs="auto">
            <Grid container justifyContent="center" spacing={4}>
              <Grid key={'key'} item xs="auto">
                <Paper elevation={3} className={classes.paperPastLesson}>
                  <div className={classes.paperPastLessonWrapper}>
                    <div className={classes.paperPastLessonImgWrapper}>
                      <img
                        className={classes.paperPastLessonImg}
                        src="https://mir-s3-cdn-cf.behance.net/project_modules/disp/ea7a3c32163929.567197ac70bda.png"
                        alt="avatar"
                      />
                    </div>
                    <div className={classes.paperPastContentWrapper}>
                      <div>
                        <Typography className={classes.paperText}>Номер группы:</Typography>
                        <Typography className={classes.paperText}>Количество учеников:</Typography>
                      </div>
                      <div>
                        <Typography className={classes.paperText}>Класс:</Typography>
                      </div>
                      <div className={classes.buttonWrapper}>
                        <Button sx={{
                          fontSize: '13px',
                          backgroundColor: '#5E81F4',
                          color: 'white',
                          '&:hover': {
                          backgroundColor: '#5E81F4'}}}>См. профиль</Button>
                      </div>
                    </div>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    </div>
  );
};

type MapStateToPropsType = {
  teacherData: TeacherInfoType;
  students: StudentsType[];
  currentCourse: CoursesType;
};

let MapStateToProps = (state: AppStateType) => ({
  teacherData: state.teacherData.teacherData,
  students: state.teacherData.students,
  currentCourse: state.teacherData.currentCourse,
});

export default connect<MapStateToPropsType, null, {}, AppStateType>(MapStateToProps)(
  withRouter(StudentsPage),
);
