import {
  CoursesType,
  StudentsType,
  TeacherInfoType,
  TeacherInfoUpdateType,
  TeacherStudentInfoUpdateType,
} from '../ducs/teacher/actions';
import createAxiosInstance from './CreateAxiosInstance';
import { AUTH_ENDPOINT, getAuthUserEmail, getAuthUserId, REALM_NAME } from '../KeycloakHelper';

export const ENDPOINT = process.env.REACT_APP_BASE_URL || 'https://testinfra.online/api';

const instanceApi = createAxiosInstance(ENDPOINT);
const instanceAuth = createAxiosInstance(AUTH_ENDPOINT);

type getLessonsType = {
  fromPlannedDateTime?: string;
  toPlannedDateTime?: string;
  studentIds?: number[];
  teacherIds?: number[];
  status?: string;
};
export type changeLessonStatusType = {
  tId: number;
  lId: number;
  status: string;
};

export type getLessonsStats = {
  id: number;
  from: string;
  to: string;
};

export type calendarDataPost = {
  teacherId: number;
  studentId: number;
  subject: string;
  activityType: string;
  fromDateTime: string;
  endDateTime: string;
  durationMinutes: number;
  repeatable: boolean;
  repeatWeekDays: string[];
};

export type calendarDataUpdate = {
  exceptionDate: string;
  newCalendarDate?: {
    newDateTime: string;
    durationMinutes: number;
  };
};

export type calendarUpdateType = {
  newDate: string;
  localTime: string;
  duration: number;
};

export type calendarDataGet = {
  teacherId: number;
  studentId: number;
  subject: string;
  activityType: string;
  fromLocalDate: string;
  endLocalDate: string;
  durationMinutes: number;
  repeatable: boolean;
  repeatWeekDays: string[];
  localTime: string;
  zoneOffset: string;
  id: number;
  calendarUpdate: calendarUpdateType[];
  calendarExceptions: {
    fromLocalDate: string;
  }[];
};

export interface postTheoryType {
  name?: string;
  author: number;
  payload?: string;
  course?: CoursesType;
  sections?: CoursesType[];
  videoLinks?: string[];
  links?: string[];
  documents?: string[];
}

export interface postSectionType {
  "value": string,
  "course": {
    "owner": string,
    "value": string
  }
}

export interface LessonsSearchType {
  id: number;
  fromDateTime: string;
  toDateTime: string;
  studentId: number;
  teacherId: number;
  status: string;
  subject: string;
  lessonType: string;
}

export interface getTheoryType extends postTheoryType {
  id?: number;
}

export type postImageType = {
  payload: string;
};

export const teacherAPI = {
  changeLessonStatus({ tId, lId, status }: changeLessonStatusType) {
    return instanceApi
      .patch<TeacherInfoType>(`/teachers/${tId}/lessons/${lId}`, { status })
      .then((response) => {
        return response.data;
      });
  },
  getLessons({
    fromPlannedDateTime,
    toPlannedDateTime,
    teacherIds,
    studentIds,
    status,
  }: getLessonsType) {
    return instanceApi
      .post<LessonsSearchType>(`lessons/search`, {
        teacherIds,
        studentIds,
        fromPlannedDateTime,
        toPlannedDateTime,
        status,
      })
      .then((response) => {
        return response.data;
      });
  },
  getCurrentTeacherInfo() {
    return instanceApi.get<TeacherInfoType>(`teachers/current`).then((response) => {
      return response.data;
    });
  },
  createTeacher() {
    return instanceApi.post<TeacherInfoType>(`teachers/create`).then((response) => {
      return response.data;
    });
  },
  updateTeacherInfo(data: TeacherInfoUpdateType, id: number) {
    const { firstName, lastName, middleName, country, city } = data;
    return instanceApi
      .patch<TeacherInfoType>(`/teachers/${id}`, { firstName, lastName, middleName, country, city })
      .then((response) => {
        return response;
      });
  },
  updateTeacherStudentInfo(data: TeacherStudentInfoUpdateType, id: number, cardId: number) {
    return instanceApi.put(`/teachers/${id}/students/${cardId}`, data).then((response) => {
      return response;
    });
  },
  createStudent(id: number, data: { email: string; course: CoursesType }) {
    return instanceApi.post<StudentsType>(`/teachers/${id}/students`, data).then((response) => {
      return response.data;
    });
  },
  getStudents(id: number, course: { teacherCourse: CoursesType }) {
    return instanceApi
      .post<StudentsType[]>(`/teachers/${id}/students/search`, course)
      .then((response) => {
        return response.data;
      });
  },
  getCourses(id: number) {
    return instanceApi.get<CoursesType[]>(`/teachers/${id}/courses`).then((response) => {
      return response.data;
    });
  },
  setCourse(id: number, data: CoursesType) {
    return instanceApi.post(`/teachers/${id}/courses`, data).then((response) => {
      return response.data;
    });
  },
  deleteCourses(id: number, data: CoursesType) {
    return instanceApi.delete(`/teachers/${id}/courses`, { data }).then((response) => {
      if (response.status !== 200) {
        throw new Error('Error during deleting courses');
      }
    });
  },
  addBalance(id: number, studentId: number, count: number) {
    return instanceApi
      .post(`/teachers/${id}/student/${studentId}/balance`, null, { params: { count } })
      .then((response) => {
        return response.data;
      });
  },
};

export const lessonsAPI = {
  getLessonsStats({ id, from, to }: getLessonsStats) {
    return instanceApi
      .get<TeacherInfoType>(`lessons/${id}/stats?from=${from}&to=${to}`)
      .then((response) => {
        return response.data;
      });
  },
  postTheory(data: postTheoryType) {
    return instanceApi.post<getTheoryType>(`/contents`, data).then((response) => {
      return response.data;
    });
  },
  postContentSection(data: postSectionType) {
    return instanceApi.post(`/contents/section`, data).then((response) => {
      return response.data;
    });
  },
  searchContentSection(data: {courseName: string, owner: string}) {
    return instanceApi.post<CoursesType[]>(`/contents/section/search`, data).then((response) => {
      return response.data;
    });
  },
  getTheoryList() {
    return instanceApi.get<getTheoryType[]>(`/contents`).then((response) => {
      return response.data;
    });
  },
  getTheory(id: number) {
    return instanceApi.get<getTheoryType>(`/contents/${id}`).then((response) => {
      return response.data;
    });
  },
  deleteTheory(id: number) {
    return instanceApi.delete(`/contents/${id}`).then((response) => {
      if (response.status !== 200) {
        throw new Error('Error during deleting the theory');
      }
    });
  },
  postImage(data: postImageType) {
    return instanceApi
      .post<any>(
        `/image`,
        { payload: data },
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        },
      )
      .then((response) => {
        return response.data;
      });
  },
};

export const authAPI = {
  updateUserInfo(firstName: string, lastName: string) {
    return instanceAuth
      .post(`/realms/${REALM_NAME}/account`, {
        id: getAuthUserId(),
        email: getAuthUserEmail(),
        firstName,
        lastName: lastName,
      })
      .then((response) => {
        if (response.status !== 204) {
          throw new Error("Can't update info in Identity");
        }
        return response;
      });
  },
};

export const calendarAPI = {
  createLesson(data: calendarDataPost) {
    return instanceApi.post(`/calendar`, data).then((response) => {
      if (response.status !== 200) {
        throw new Error('Error during appointment creation');
      }
    });
  },
  getLessons(id: number) {
    return instanceApi.get<calendarDataGet[]>(`/calendar/${id}`).then((response) => {
      if (response.status !== 200) {
        throw new Error("Can't get calendar info");
      }
      return response.data;
    });
  },
  updateLesson(id: number, data: calendarDataUpdate) {
    return instanceApi.put(`/calendar/${id}/update`, data).then((response) => {
      if (response.status !== 200) {
        throw new Error('Error during updating the appointment');
      }
    });
  },
  deleteLesson(id: number) {
    return instanceApi.delete(`/calendar/${id}`).then((response) => {
      if (response.status !== 200) {
        throw new Error('Error during deleting the appointment');
      }
    });
  },
};
