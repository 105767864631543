import React from 'react';
import SwaggerUI from 'swagger-ui-react';
import { getToken } from '../KeycloakHelper';
import 'swagger-ui-react/swagger-ui.css';

// @ts-ignore
const requestInterceptor = (req) => {
  return {
    ...req,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
};

const Swagger = () => {
  return (
    <div>
      <SwaggerUI
        url="https://testinfra.online/api/v3/api-docs"
        requestInterceptor={requestInterceptor}
      />
    </div>
  );
};

export default Swagger;
