import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const muiStyle = {
    addSubjectButton: {
        margin: '20px 0 0 24px',
        backgroundColor: '#5E81F4',
    },
    button: {
        width: 'fit-content',
        backgroundColor: '#5E81F4',
        marginRight: '15px',
    },
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoForm: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    avatar: {
      width: 100,
      height: 100,
      marginRight: theme.spacing(5),
    },
    columnsContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(2),
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: theme.spacing(2),
    },
    textField: {
      marginBottom: theme.spacing(2),
    },
    buttonsWrap: {
      display: 'flex',
    },
    checkboxRoot: {
      display: 'flex',
      flexDirection: 'column',
      margin: '5px 0 40px 0',
    },
    text: {
      marginBottom: '10px',
    },
    utc: {
      marginBottom: '30px',
    },
    root: {
      height: '25px',
      margin: '5px 5px 0 0',
    },
    chosenChip: {
      backgroundColor: '#5E81F4',
      color: 'white',
      '&:focus': {
        backgroundColor: '#5E81F4',
        color: 'white',
      },
    },
    addSubjectWrapper: {
      display: 'flex',
    },
  }),
);
