import React, {FC} from 'react';
import {useStyles} from '../StudentsPageStyle';
import {Formik} from 'formik';
import * as Yup from "yup";
import InputRow from '../../../Components/InputRow/InputRow';
import {Button} from '@mui/material';
import {StudentPersonalInfoProps} from './StudentPersonalInfo';
import {triggerTeacherStudentInfoUpdateAction} from '../../../ducs/teacher/actions';

type StudentParentsInfoProps = StudentPersonalInfoProps;

const StudentParentsInfo: FC<StudentParentsInfoProps> = (props) => {
  const { chosenStudent, dispatch, teacherId, cardId, setEdit, currentTeacherCourse } = props;

  const classes = useStyles();

  const validation = Yup.object({
    firstName: Yup.string().required('Обязательное поле'),
    lastName: Yup.string().required('Обязательное поле'),
    middleName: Yup.string().required('Обязательное поле'),
      relationName: Yup.string().required('Обязательное поле'),
    phone: Yup.string().required('Обязательное поле'),
  });

  return (
    <div className={classes.parentsInfoWrapper}>
      <Formik
        initialValues={{
          firstName: chosenStudent?.parent?.firstName,
          lastName: chosenStudent?.parent?.lastName,
          middleName: chosenStudent?.parent?.middleName,
          relationName: chosenStudent?.parent?.relationName,
          phone: chosenStudent?.parent?.phone,
          email: chosenStudent?.parent?.email,
        }}
        validationSchema={validation}
        onSubmit={
          async (values, { setSubmitting }) => {
            setSubmitting(true);
            await dispatch(
                triggerTeacherStudentInfoUpdateAction(
                    {
                      phone: chosenStudent.phone,
                      parent: {
                        firstName: values.firstName,
                        lastName: values.lastName,
                        middleName: values.middleName,
                        relationName: values.firstName,
                        phone: values.phone,
                        email: values.email,
                      },
                    },
                    teacherId,
                    cardId,
                    { teacherCourse: currentTeacherCourse },
                ),
            );
            setSubmitting(false);
          }
        }
    >
          {({
                values,
                errors,
                touched,
                dirty,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
        <InputRow
          inputsArray={[
            {
              name: 'lastName',
              id: 'lastName',
              value: values.lastName,
              onChange: handleChange,
                onBlur: handleBlur,
              label: 'Фамилия',
                errors,
                touched,
            },
            {
              name: 'firstName',
              id: 'firstName',
              value: values.firstName,
              onChange: handleChange,
                onBlur: handleBlur,
              label: 'Имя',
                errors,
                touched,
            },
            {
              name: 'middleName',
              id: 'middleName',
              value: values.middleName,
              onChange: handleChange,
                onBlur: handleBlur,
              label: 'Отчество',
                touched,
                errors,
            },
          ]}
        />
        <InputRow
          inputsArray={[
            {
              name: 'relationName',
              id: 'relationName',
              value: values.relationName,
              onChange: handleChange,
                onBlur: handleBlur,
              label: 'Статус',
                errors,
                touched,
            },
          ]}
        />
        <InputRow
          inputsArray={[
            {
              name: 'phone',
              id: 'phone',
              value: values.phone,
              onChange: handleChange,
                onBlur: handleBlur,
              label: 'Контактный телефон',
                errors,
                touched,
            },
            {
              name: 'email',
              id: 'email',
              value: values.email,
              onChange: handleChange,
                onBlur: handleBlur,
              label: 'Почта',
              disabled: true,
                errors,
                touched,
            },
          ]}
        />
        <div>
          <Button
            sx={{
                maxWidth: '100%',
                width: 'fit-content',
                marginTop: '20px',
                backgroundColor: '#5E81F4',
            }}
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={!dirty || isSubmitting}
          >
            Сохранить информацию
          </Button>
          <Button
            sx={{
                maxWidth: '100%',
                width: 'fit-content',
                margin: '20px 0 0 20px',
            }}
            color="primary"
            variant="outlined"
            fullWidth
            onClick={() => setEdit(false)}
            disabled={isSubmitting}
          >
            Вернуться в карточку
          </Button>
        </div>
      </form>
          )}
    </Formik>
    </div>
  );
};

export default StudentParentsInfo;
