import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    barWrapper: {
      display: 'flex',
      width: '1050px',
    },
    upBar: {
      display: 'flex',
      flex: '1 1 auto',
      width: '750px',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
    },
    date: {
      fontSize: '14px',
      color: '#111026',
      fontWeight: 600,
      margin: '33px 10px 33px 0',
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
    inputRoot: {
      color: '#5E81F4',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      cursor: 'pointer',
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '0px',
      '&:focus': {
        width: '20ch',
        cursor: 'text',
      },
    },
    userWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '16px 33px 16px 33px',
      flex: '1 1 auto',
      width: '300px',
    },
    personalInfo: {
      display: 'flex',
      alignItems: 'center',
      border: 'none',
      textDecoration: 'none',
      outline: 'none',
      color: 'black',
    },
    personalInfoText: {
      marginLeft: '15px',
    },
    avatarWrap: {
      display: 'flex',
      width: '100%',
      border: '1px solid #C2C1C1',
      borderRadius: '30px',
      cursor: 'pointer',
    },
    text: {
      display: 'flex',
      fontSize: '14px',
      color: '#A2A2C2',
      margin: '10px 0 10px 10px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    personalInfoWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    textWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    icon: {
      display: 'flex',
      color: '#A2A2C2',
      margin: '15px 10px',
    },
    popperWrap: {
      zIndex: 100,
    },
    paper: {
      boxShadow: '0 6px 10px gray',
    },
  }),
);
