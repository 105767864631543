import { createStyles, makeStyles } from '@mui/styles';

export const muiStyles = {
    stepperContent: {
        margin: '16px 0',
    },
    backButton: {
        marginRight: '30px',
    },
    nextButton: {
        backgroundColor: '#5E81F4',
        '&:hover': {
            backgroundColor: '#5E81F4',
        },
    },
    addButton: {
        backgroundColor: '#5E81F4',
        color: 'white',
        margin: '0 10px',
        '&:hover': {
            backgroundColor: '#5E81F4',
        },
    },
    attachName: {
        marginBottom: '5px',
    },
    finalStepLabel: {
        margin: '20px 0',
    },
}

export const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '1050px',
      minHeight: '100vh',
      backgroundColor: '#F5F5FB',
    },
    stepContentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      margin: '20px 0 20px 40px',
      alignItems: 'flex-start',
    },
    filterField: {
      display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        paddingRight: "40px",
    },
    filter: {
      cursor: 'pointer',
    },
      alignCenter: {
          alignItems: 'center',
      },
    editorWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '900px',
      margin: '16px 0',
    },
    editorBody: {
      backgroundColor: 'white',
      minHeight: '300px',
    },
    root: {
      width: '100%',
    },
    stepIcon: {
      color: '#5E81F4!important',
    },
    buttonsWrap: {
      display: 'flex',
      justifyContent: 'center',
    },
    stepperContent: {
      margin: '16px 0',
    },
    formControl: {
      margin: '16px 0',
      minWidth: '400px !important',
    },
    dropZone: {
      width: '470px',
      height: '150px',
      border: '2px dashed lightGray',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '20px 0',
      color: 'lightGray',
    },
    inputFieldWrapper: {
      marginTop: '20px',
      '&:first-child': {
        marginTop: '40px',
      },
    },
    inputWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '20px',
      width: '470px',
    },
    step1textField: {
      minWidth: '400px !important',
        marginBottom: '10px !important',
    },
    textField: {
      width: '80%',
    },
    chipWrapper: {
      marginBottom: '5px',
    },
    attachmentWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    attachmentName: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    paperVideoWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '1000px',
      padding: '20px 0',
      marginBottom: '20px',
    },
    paperContentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '1000px',
      padding: '20px',
      marginBottom: '20px',
    },
    finalVideo: {
      marginBottom: '40px',
    },
    introContentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 35px',
    },
  }),
);
