import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  appWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  appContent: {
    display: 'flex',
    width: '1280px',
    justifyContent: 'start',
    minHeight: '100vh',
  },
  innerContent: {
    display: 'flex',
    width: '1050px',
    flexDirection: 'column',
    backgroundColor: '#F5F5FB',
  },
  scrollContainer: {
    margin: 'auto',
    maxHeight: '100%',
    overflow: 'auto',
  },
  loader: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
