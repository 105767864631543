import { NavLink } from 'react-router-dom';
import {Card, CardActionArea, CardMedia, CardContent, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useStyles } from './LessonWidgetStyle';

type Props = {
  alt: string;
  img: string;
  name: string;
  title: string;
  description: any;
  link: string;
};

export const LessonWidget: FC<Props> = ({ alt, img, name, title, description, link }) => {
  const classes = useStyles();

  return (
    <div>
      <NavLink to={link} className={classes.link}>
        <Card className={classes.cardRoot}>
          <CardActionArea>
            <CardMedia component="img" alt={alt} height="160" image={img} title={title} />
            <CardContent>
              <Typography gutterBottom variant="h6" component="h6">
                {name}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {description}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </NavLink>
    </div>
  );
};

export default LessonWidget;
