import React, { FC } from 'react';
import { useStyles, muiStyles } from '../CreateLessonStyle';
import { Typography, Button } from '@mui/material';

type Props = {
  setShowIntro(arg: boolean): void;
  setRedirect(arg: boolean): void;
};

const Intro: FC<Props> = (props) => {
  const { setRedirect, setShowIntro } = props;
  const classes = useStyles();

  const handleBack = () => setRedirect(true);
  const handleNext = () => setShowIntro(false);

  return (
    <div className={classes.introContentWrapper}>
      <Typography variant="h4" paragraph>
        Инструкция
      </Typography>
      <Typography variant="body1" paragraph align="justify">
        <Typography variant="h6">Шаг 1:</Typography>
        Введите название темы, которая будет рассматриваться в приведенном материале.
        <br />
        Для того, чтобы в дальнейшем без труда можно было найти подходящую тему, на платформе
        используются «теги», которые могут отразить суть темы (их может быть несколько или более).
        <br />
        Также, отнесите тему к какому либо классу или классам. Данный фильтр поможет исключить те
        темы с тегами, которые не относятся к нужному классу, что в дальнейшем сократит время для
        поиска нужной темы.
      </Typography>
      <Typography variant="body1" paragraph align="justify">
        <Typography variant="h6">Шаг 2:</Typography>
        На данном этапе создается вся основная часть, включающая в себя текст, формулы, таблицы и
        изображения.
      </Typography>
      <Typography variant="body1" paragraph align="justify">
        <Typography variant="h6">Шаг 3:</Typography>
        При необходимости, к теоретическому материалу можно добавить:
        <br />
        ссылки на видеофайл из открытых источников, таких как: YouTube, RuTube;
        <br />
        cсылки на статьи, приведенные в сети Интернет;
        <br />
        прикрепить файлы (формата .doc; .docx; .pdf; .xlsx) с вашего ПК.
      </Typography>
      <Typography variant="body1" paragraph align="justify">
        <Typography variant="h6">Шаг 4:</Typography>
        Перед тем, как нажать на кнопку Готово, проверяем созданный материал на ошибки и опечатки.
      </Typography>
      <Typography variant="body1" paragraph align="justify">
        Все отлично?! Нажимаем на кнопку «Готово», теперь теория по заданной теме появится в
        соответствующем разделе и готова для отправки ученикам на изучение!)
      </Typography>
      <div className={classes.buttonsWrap}>
        <Button onClick={handleBack} sx={muiStyles.backButton}>
          Выход
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={muiStyles.nextButton}
          onClick={handleNext}
        >
          Старт
        </Button>
      </div>
    </div>
  );
};

export default Intro;
