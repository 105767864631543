import React, { FC } from 'react';
import { useStyles } from './UserCardStyle';
import { Button, Typography, Paper } from '@mui/material';

type Props = {
  imgUrl: string;
  name: string;
  lessonsNumber: number;
  date?: string;
  levelName?: string;
  buttonName?: string;
};

export const UserCard: FC<Props> = ({
  imgUrl,
  name,
  lessonsNumber,
  levelName,
  date,
  buttonName,
}) => {
  const classes = useStyles();

  return (
    <Paper elevation={3} className={classes.paper}>
      <div>
        <div className={classes.paperImgWrapper}>
          <img className={classes.paperImg} src={imgUrl} alt="avatar" />
        </div>
        <div className={classes.paperContentWrapper}>
          <Typography className={classes.paperText}>{name}</Typography>
          <Typography className={classes.paperText}>Количество уроков: {lessonsNumber}</Typography>
          <Typography className={classes.paperText}>Класс: {levelName}</Typography>
          <Typography className={classes.paperText}>Дата: {date}</Typography>
          {buttonName ? (
            <div className={classes.paperButtonWrapper}>
              <Button className={classes.paperButton}>{buttonName}</Button>
            </div>
          ) : null}
        </div>
      </div>
    </Paper>
  );
};

export default UserCard;
