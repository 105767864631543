export const widgetItems = [
  {
    link: '/lessons/1',
    alt: 'Lesson 1',
    img: 'https://image.shutterstock.com/image-illustration/lettering-lesson-1-on-green-260nw-1780698029.jpg',
    name: 'Числа и простые вычисления',
    title: 'Lesson 1',
    description: () => (
      <div>
        <b>Вы изучите:</b>
        <li>Действия с обыкновенными дробями</li>
        <li>Действия с десятичными дробями</li>
        <li>Числа и их сравнение</li>
      </div>
    ),
  },
  {
    link: '/homework/1',
    alt: 'HomeWork 1',
    img: 'https://image.shutterstock.com/image-illustration/lettering-lesson-1-on-green-260nw-1780698029.jpg',
    name: 'Числа и простые вычисления',
    title: 'HomeWork 1',
    description: () => (
      <div>
        <b>Домашнее задание</b>
        <div>Решите итоговый тест</div>
      </div>
    ),
  },
];
