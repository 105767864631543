import React, { FC } from 'react';
import {InputLabel, TextField, Typography} from '@mui/material';
import { useStyles } from './InputRowStyle';
import {FormikErrors, FormikTouched} from 'formik';

type inputType = {
  name?: string;
  id?: string;
  value?: string | number;
  label?: string;
  disabled?: boolean;
  errors?: FormikErrors<object>;
  touched?: FormikTouched<object>;
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: FocusEvent) => void;
};

type InputRowProps = {
  inputsArray: inputType[];
};

const InputRow: FC<InputRowProps> = (props) => {
  const classes = useStyles();
  const { inputsArray } = props;

  return (
    <div className={classes.row}>
      {inputsArray.map((it: any) => {
        const { name, label, id, value, onChange, errors, touched, ...rest } = it;
        return (
          <div className={classes.input}>
            <InputLabel htmlFor={it.name}>{it.label}</InputLabel>
            <TextField
              sx={{margin: '5px 0 15px 0'}}
              id={it.id}
              name={it.name}
              variant="outlined"
              value={it.value}
              onChange={it.onChange}
              {...rest}
            />
            <Typography
                sx={{
                  position: 'absolute',
                  bottom: "-3px",
                }} variant="caption" color="error">{errors?.[it.name] && touched?.[it.name] && errors?.[it.name]}</Typography>
          </div>
        );
      })}
    </div>
  );
};

export default InputRow;
