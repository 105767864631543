import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './APP/App';
import {ReactKeycloakProvider} from '@react-keycloak/web';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from './store/redux-store';
import {authClient, handleOnEvent, initOptions, loadingComponent} from './KeycloakHelper';
import {PrivateComponent} from './Components/PrivateComponent';
import {adaptV4Theme, createTheme, ThemeProvider} from "@mui/material/styles";
import StylesProvider from "@mui/styles/StylesProvider";
import jssExtend from 'jss-plugin-extend';
import {create} from 'jss';
import jssPreset from '@mui/styles/jssPreset';

const jss = create({
    plugins: [jssExtend(), ...jssPreset().plugins],
});

const mainFont = createTheme(adaptV4Theme({
    typography: {
        fontFamily: ['arial'].join(','),
    },
}));

ReactDOM.render(
    <React.StrictMode>
        <ReactKeycloakProvider
            authClient={authClient}
            initOptions={initOptions}
            LoadingComponent={loadingComponent}
            onEvent={(event, error) => handleOnEvent(event, error)}
        >
            <BrowserRouter>
                <Provider store={store}>
                    <PrivateComponent>
                        <ThemeProvider theme={mainFont}>
                            <StylesProvider jss={jss}>
                                <App/>
                            </StylesProvider>
                        </ThemeProvider>
                    </PrivateComponent>
                </Provider>
            </BrowserRouter>
        </ReactKeycloakProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
