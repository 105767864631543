import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { getToken, isAuthenticated } from '../KeycloakHelper';

const createAxiosInstance = (url: string): AxiosInstance => {
  const axiosInstance = axios.create({
    baseURL: url,
  });

  axiosInstance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      if (isAuthenticated()) {
        config.headers.Authorization = `Bearer ${getToken()}`;
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  return axiosInstance;
};

export default createAxiosInstance;
