import React, { FC } from 'react';
import {muiStyles, useStyles} from '../CreateLessonStyle';
import { Paper, Typography } from '@mui/material';
import YouTubePlayer from 'react-player/youtube';

type Props = {
  name: string;
  markup: string;
  attachedLinksVideo: string[];
  attachedLinks: string[];
  attachedFiles?: any[];
};

const LessonItem: FC<Props> = (props) => {
  const classes = useStyles();
  const { markup, attachedLinks, attachedLinksVideo, name } = props;

  return (
    <div className={classes.stepContentWrapper}>
      {(!!name || !!attachedLinksVideo.length) && (
        <Paper classes={{ root: classes.paperVideoWrapper }}>
          <Typography sx={muiStyles.finalStepLabel} variant="h4">
            {name}
          </Typography>
          {attachedLinksVideo &&
            attachedLinksVideo.map((it) => (
              <div key={it} className={classes.finalVideo}>
                <YouTubePlayer url={it} controls={true} />
              </div>
            ))}
        </Paper>
      )}
      {!!markup && (
        <Paper classes={{ root: classes.paperContentWrapper }}>
          <div dangerouslySetInnerHTML={{ __html: markup }} />
        </Paper>
      )}
      {!!attachedLinks.length && (
        <Paper classes={{ root: classes.paperContentWrapper }}>
          <Typography>Ссылки на материалы:</Typography>
          <ol>
            {attachedLinks.map((it) => (
              <li key={it}>
                <a href={it}>{it}</a>
              </li>
            ))}
          </ol>
          {/*<Typography>Список документов:</Typography>*/}
          {/*<ol>*/}
          {/*    {attachedFiles.map(it => <li key={it.name}>{it.name}</li>)}*/}
          {/*</ol>*/}
        </Paper>
      )}
    </div>
  );
};

export default LessonItem;
