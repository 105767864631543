import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    tabsWrappedRoot: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    wrapper: {
      display: 'block',
      width: '1050px',
      backgroundColor: '#F5F5FB',
    },
    textWrapper: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      padding: '30px 30px 15px 30px',
    },
    buttonWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    carouselWrap: {
      display: 'inline-flex',
      margin: '5px 0 5px 10px',
    },
    paperText: {
      display: 'flex',
      justifyContent: 'center',
    },
    paperPastLessonWrapper: {
      display: 'flex',
      marginRight: '-20px',
      opacity: 0.2,
      pointerEvents: 'none',
    },
    paperPastLesson: {
      display: 'flex',
      height: '50px',
      width: '100%',
      margin: '0 20px',
    },
    paperPastLessonImgWrapper: {
      display: 'flex',
      margin: '0 20px',
    },
    paperPastLessonImg: {
      width: '50px',
      height: '50px',
      borderRadius: '20px',
    },
    paperPastContentWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '900px',
    },
    parentsInfoWrapper: {
      display: 'flex',
      marginLeft: '180px',
    },
    infoWrapper: {
      display: 'flex',
    },
    scheduleWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    infoImg: {
      margin: '0 40px',
    },
    infoGoal: {
      margin: '40px 0',
    },
    tabRoot: {
      backgroundColor: theme.palette.background.paper,
      margin: '0 1%',
      width: '98%',
    },
    chip: {
      paddingRight: '5px',
    },
    contentWrapper: {
      display: 'flex',
      backgroundColor: '#CCD5FF',
      borderRadius: '5px',
      fontSize: '18px',
      marginBottom: '10px',
      padding: '0 10px',
      justifyContent: 'space-between',
    },
    addBallanceButton: {
      width: 'fit-content',
      margin: '35px 0 0 -15px',
    },
    addBalanceWrapper: {
      display: 'flex',
      width: 'fit-content',
      marginTop: '20px',
    },
    scheduleLineWrapper: {
      display: 'flex',
      marginTop: '20px',
    },
    timePicker: {
      display: 'flex',
      width: '140px',
      height: '50px',
      marginLeft: '20px',
    },
    datePicker: {
      display: 'flex',
      width: '280px',
      height: '50px',
      marginLeft: '20px',
    },
    selectRepeatable: {
      display: 'flex',
      width: '280px',
      height: '50px',
      marginLeft: '20px',
    },
    addLessonButton: {
      display: 'flex',
      width: '280px',
      marginTop: '20px',
    },
    saveLessonButton: {
      display: 'flex',
      width: '280px',
      marginTop: '20px',
      backgroundColor: '#5E81F4',
      color: 'white',
    },
    studentCardWrapper: {
      margin: '15px',
    },
    studentCardContentWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '20px',
    },
    studentCardAvatarWrapper: {
      display: 'flex',
      marginBottom: '5px',
      paddingTop: '10px',
    },
    avatarName: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '15px',
      fontSize: '20px',
      fontWeight: 600,
    },
    studentCardColumnWrapper: {
      width: '515px',
    },
    studentCardButtonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      margin: '5px 0 15px 0',
    },
    itemName: {
      display: 'inline-flex',
      width: '100px',
      color: '#A2A2C2',
      marginBottom: '15px',
    },
    itemAimName: {
      marginBottom: '5px',
      fontWeight: 600,
    },
    textArea: {
      marginBottom: '5px',
      fontWeight: 600,
      borderColor: '#FFF',
      '& textarea': {
        borderColor: '#FFF',
        borderRadius: '5px',
        backgroundColor: '#F5F5FB',
        minWidth: '480px',
        minHeight: '45px',
      },
    },
    addStudentText: {
      margin: '15px 0 0 30px',
    },
      loader: {
        display: 'flex',
          justifyContent: 'center',
    },
  }),
);
