import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    row: {
      display: 'flex',
        position: 'relative',
    },
    input: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: '24px',
    },
  }),
);
