import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      width: '230px',
      backgroundColor: 'white',
    },
    iconMain: {
      margin: '13% 8% 20% 15%',
      color: '#5E81F4',
    },
    icon: {
      margin: '6% 8% 0 15%',
      color: '#5E81F4',
    },
    boxPosition: {
      display: 'flex',
      justifyContent: 'flex-start',
      width: '230px',
    },
    menu: {
      margin: '7% 0 5% 0',
      fontWeight: 500,
      fontSize: 14,
      color: '#A2A2C2',
      textDecoration: 'none',
    },
    mainMenu: {
      fontWeight: 600,
      color: 'black',
    },
  }),
);
