import { call, put, takeEvery } from 'redux-saga/effects';
import { calendarAPI, calendarDataGet, calendarDataPost, calendarDataUpdate } from '../../API/API';
import { CALENDAR } from './actions-type';
import { getLessonScheduleAction } from './actions';
import { StudentsType } from '../teacher/actions';
import { AppointmentModel } from '@devexpress/dx-react-scheduler';
import moment from 'moment';
import { getPreparedAppointment } from '../../utils/calendarUtils';

type InitialState = {
  appointmentsData: calendarDataPost[];
};

const initialState: InitialState = {
  appointmentsData: [],
};

// TODO: action type?
export const calendarReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CALENDAR.GET_LESSON_SCHEDULE:
      return {
        ...state,
        appointmentsData: action.data,
      };
    default:
      return state;
  }
};

function* createLessonSchedule(action: {
  type: typeof CALENDAR.TRIGGER_CREATE_LESSON_SCHEDULE;
  data: calendarDataPost & { setRedirect?: (arg: boolean) => void };
}): any {
  const { setRedirect, ...rest } = action.data;
  try {
    yield call(() => {
      return calendarAPI.createLesson(rest);
    });
    const data = yield call(() => {
      return calendarAPI.getLessons(action.data.teacherId);
    });
    yield put(getLessonScheduleAction(data));
    setRedirect && setRedirect(true);
  } catch (error) {
    console.log('Fetching error');
  }
}

function* updateLessonSchedule(action: {
  type: typeof CALENDAR.UPDATE_LESSON_SCHEDULE;
  data: calendarDataUpdate;
  id: number;
  teacherId: number;
  students: StudentsType[];
}): any {
  try {
    yield call(() => {
      return calendarAPI.updateLesson(action.id, action.data);
    });
    yield call(() => {
      return getLessonSchedule({
        type: CALENDAR.TRIGGER_GET_LESSON_SCHEDULE,
        id: action.teacherId,
        students: action.students,
      });
    });
  } catch (error) {
    console.log('updateLessonSchedule error');
  }
}

function* deleteLessonSchedule(action: {
  type: typeof CALENDAR.DELETE_LESSON_SCHEDULE;
  id: number;
  teacherId: number;
  students: StudentsType[];
}): any {
  try {
    yield call(() => {
      return calendarAPI.deleteLesson(action.id);
    });
    yield call(() => {
      return getLessonSchedule({
        type: CALENDAR.TRIGGER_GET_LESSON_SCHEDULE,
        id: action.teacherId,
        students: action.students,
      });
    });
  } catch (error) {
    console.log('updateLessonSchedule error');
  }
}

function* getLessonSchedule(action: {
  type: typeof CALENDAR.TRIGGER_GET_LESSON_SCHEDULE;
  id: number;
  students: StudentsType[];
}): any {
  try {
    const data: calendarDataGet[] = yield call(() => {
      return calendarAPI.getLessons(action.id);
    });
    let processedData: (AppointmentModel & { appointmentId: number })[] = [];
    data.forEach((it) => {
      if (!data.length || !action.students.length) return;
      const currStudent = action.students?.filter((st) => st.id === it.studentId)[0];
      const preparedAppointment = getPreparedAppointment(it, currStudent);
      processedData.push({
        ...preparedAppointment,
        id: `${it.id}${preparedAppointment.startDate.getDate()}`,
        appointmentId: it.id,
        studentId: currStudent.id,
      });
      if (it.repeatable) {
        const finishDate = new Date(it.endLocalDate);
        finishDate.setDate(finishDate.getDate() - 7);
        const repeatStartDay = new Date(preparedAppointment.startDate);
        const repeatEndDay = new Date(preparedAppointment.endDate);
        const title = preparedAppointment.title;
        while (finishDate >= repeatStartDay) {
          const start = new Date(repeatStartDay.setDate(repeatStartDay.getDate() + 7));
          const end = new Date(repeatEndDay.setDate(repeatEndDay.getDate() + 7));
          processedData.push({
            title,
            startDate: start,
            endDate: end,
            id: `${it.id}${start.getDate()}${start.getMonth()}`,
            appointmentId: it.id,
            studentId: currStudent.id,
          });
        }
      }
      if (it.calendarUpdate.length) {
        it.calendarUpdate.forEach((date) => {
          const updatedData = getPreparedAppointment(date, currStudent);
          processedData.push({
            ...updatedData,
            id: `${it.id}${updatedData.startDate.getDate()}${updatedData.startDate.getMonth()}`,
            appointmentId: it.id,
            studentId: currStudent.id,
          });
        });
      }
      if (it.calendarExceptions.length) {
        processedData = processedData.filter(
            (item) =>
                !it.calendarExceptions.find(
                    (date) => date.fromLocalDate === moment(item.startDate).format('YYYY-MM-DD'),
                ),
        );
      }
    });
    yield put(getLessonScheduleAction(processedData));
  } catch (error) {
    console.log('Fetching lessons error');
  }
}

export function* watchCalendarSaga() {
  yield takeEvery(CALENDAR.TRIGGER_CREATE_LESSON_SCHEDULE, createLessonSchedule);
  yield takeEvery(CALENDAR.UPDATE_LESSON_SCHEDULE, updateLessonSchedule);
  yield takeEvery(CALENDAR.DELETE_LESSON_SCHEDULE, deleteLessonSchedule);
  yield takeEvery(CALENDAR.TRIGGER_GET_LESSON_SCHEDULE, getLessonSchedule);
}
