import React, {FC, useMemo, useState} from 'react';
import {Stepper, Step, StepLabel, Button, Typography } from '@mui/material';
import {muiStyles, useStyles} from './CreateLessonStyle';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import { convertToRaw, EditorState } from 'draft-js';
import {
  triggerPostTheory,
} from '../../ducs/lessons/actions';
import { AppStateType } from '../../ducs';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import draftToHtml from 'draftjs-to-html';
import { lessonsAPI } from '../../API/API';
import LessonItem from './InnerComponents/LessonItem';
import { ENDPOINT } from '../../API/API';
import {CoursesType, TeacherInfoType} from '../../ducs/teacher/actions';
import Intro from './Steps/Intro';

type Props = {
  dispatch: any;
  teacherData: TeacherInfoType;
  currentCourse: CoursesType;
};

function getSteps() {
  return ['Вводные данные', 'Основная информация', 'Ссылки/документы', 'Готово'];
}

const CreateLesson: FC<Props> = (props) => {
  const classes = useStyles();
  const { dispatch, teacherData, currentCourse } = props;
  const [redirect, setRedirect] = useState(false);
  const [showIntro, setShowIntro] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [articleName, setArticleName] = useState('');
  const [section, setSection] = useState<CoursesType[]>();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [attachedImages, setAttachedImages] = useState([]);
  const [attachedLinks, setAttachedLinks] = useState([]);
  const [attachedLinksVideo, setAttachedLinksVideo] = useState([]);
  const steps = getSteps();
  const markup = draftToHtml(convertToRaw(editorState.getCurrentContent()));

  const getImagesToSend = () => {
    const raw = convertToRaw(editorState.getCurrentContent());
    // @ts-ignore
    const imagesArray = Object.values(raw.entityMap)
      .filter((it) => it.type === 'IMAGE')
      .map((it) => it.data.src);
    return attachedImages.filter((it) => imagesArray.includes(it.localSrc)).map((it) => it.file);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleFinish = async () => {
    let imagesUid: object[] = [];
    const promises = getImagesToSend().map((it) => lessonsAPI.postImage(it));
    Promise.all(promises).then((values) => {
      imagesUid.push(...values);
      const lessonRawContent = convertToRaw(editorState.getCurrentContent());
      const newEntityMap = Object.entries(lessonRawContent.entityMap)
        .map(([name, obj]) => ({ [name]: obj }))
        .map((it, idx) => {
          if (it[idx].type === 'IMAGE') {
            // @ts-ignore
            return {
              ...it[idx],
              data: { ...it[idx].data, src: `${ENDPOINT}/image/${imagesUid[idx]}` },
            };
          }
          return it;
        });
      // @ts-ignore
      lessonRawContent.entityMap = { ...newEntityMap };
      const markupToSend = draftToHtml(lessonRawContent);
      const data = {
        name: articleName,
        sections: section,
        course: currentCourse,
        author: teacherData.id,
        payload: markupToSend,
        videoLinks: attachedLinksVideo,
        links: attachedLinks,
      };
      dispatch(triggerPostTheory(data));
      setRedirect(true);
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function getStepContent(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        return (
          <Step1
            articleName={articleName}
            setArticleName={setArticleName}
            section={section}
            setSection={setSection}
            teacherCourse={currentCourse}
            teacherData={teacherData}
          />
        );
      case 1:
        return (
          <Step2
            editorState={editorState}
            setEditorState={setEditorState}
            attachedImages={attachedImages}
            setAttachedImages={setAttachedImages}
          />
        );
      case 2:
        return (
          <Step3
            attachedFiles={attachedFiles}
            attachedLinks={attachedLinks}
            attachedLinksVideo={attachedLinksVideo}
            setAttachedFiles={setAttachedFiles}
            setAttachedLinks={setAttachedLinks}
            setAttachedLinksVideo={setAttachedLinksVideo}
          />
        );
      case 3:
        return (
          <LessonItem
            name={articleName}
            attachedLinks={attachedLinks}
            attachedLinksVideo={attachedLinksVideo}
            markup={markup}
            attachedFiles={attachedFiles}
          />
        );
      default:
        return 'Unknown step';
    }
  }

  const isLastStep = useMemo(() => activeStep === steps.length - 1, [activeStep, steps]);

  if (redirect) return <Redirect to="/lessons" />;

  if (showIntro) return <Intro setShowIntro={setShowIntro} setRedirect={setRedirect} />;

  return (
    <div className={classes.root}>
      <Stepper style={{ padding: 24 }} activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              StepIconProps={{
                classes: {
                  active: classes.stepIcon,
                  completed: classes.stepIcon,
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography sx={muiStyles.stepperContent}>All steps completed</Typography>
            <div className={classes.buttonsWrap}>
              <Button onClick={handleReset}>Reset</Button>
            </div>
          </div>
        ) : (
          <div>
            <div className={classes.stepperContent}>{getStepContent(activeStep)}</div>
            <div className={classes.buttonsWrap}>
              <Button
                onClick={activeStep === 0 ? () => setShowIntro(true) : handleBack}
                sx={muiStyles.backButton}
              >
                Назад
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={muiStyles.nextButton}
                disabled = {isLastStep && (!section?.length || !articleName)}
                onClick={isLastStep ? handleFinish : handleNext}
              >
                {isLastStep ? 'Готово' : 'Далее'}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

type MapStateToPropsType = {
  teacherData: TeacherInfoType;
  currentCourse: CoursesType;
};

let MapStateToProps = (state: AppStateType) => ({
  teacherData: state.teacherData.teacherData,
  currentCourse: state.teacherData.currentCourse,
});

export default connect<MapStateToPropsType, null, {}, AppStateType>(MapStateToProps)(CreateLesson);
