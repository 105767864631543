import { createStyles, makeStyles } from '@mui/styles';

export const muiStyles = {
    menuTooltip: {
        position: 'absolute',
        right: '-140px',
        top: '-80px',
        padding: '10px',
        cursor: 'pointer',
    },
    addLessonButton: {
        fontSize: '13px',
        backgroundColor: '#5E81F4',
        color: 'white',
        '&:hover': {
            backgroundColor: '#5E81F4',
        },
    },
    formControl: {
        margin: '16px 0 !important',
        width: '200px',
    },
    paperContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '800px',
    },
    repeatText: {
        fontSize: '13px',
        marginRight: '10px',
        color: '#6B7280',
    },
    exitButton: {
        fontSize: '13px',
        backgroundColor: '#FFFFFF',
        color: 'black',
        marginRight: '10px',
        '&:hover': {
            backgroundColor: '#FFFFFF',
        },
    },
    saveButton: {
        fontSize: '13px',
        backgroundColor: '#5E81F4',
        color: 'white',
        marginRight: '10px',
        '&:hover': {
            backgroundColor: '#5E81F4',
        },
    },
}

export const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '1050px',
      backgroundColor: '#F5F5FB',
      '@global': {
        '.SaveButton-button.SaveButton-button': {
          backgroundColor: '#5E81F4',
          '&:hover': {
            backgroundColor: '#5E81F4',
          },
        },
      },
    },
    addLessonWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '1050px',
      backgroundColor: '#F5F5FB',
      alignItems: 'center',
    },
    innerAddLessonWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    upWrapper: {
      display: 'flex',
    },
    bottomWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      height: '60px',
      borderTop: '1px solid #E5E7EB',
    },
    buttonGroupWrapper: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: '170px',
      padding: '40px 25px 15px 25px',
      backgroundColor: '#F9FAFB',
      borderRight: '1px solid #E5E7EB',
      borderRadius: '4px 0 0 4px',
    },
    dayPickerWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '620px',
    },
    dayPickerChange: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      '&:first-child': {
        '@global': {
          '.TextField-end-wrapper': {
            display: 'none',
          },
        },
      },
      '&:last-child': {
        '@global': {
          '.day-picker-wrapper': {
            display: 'none',
          },
          '.TextField-start-wrapper': {
            display: 'none',
          },
          '.TextField-end-wrapper': {
            marginTop: '10px',
          },
        },
      },
    },
    timePickerWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 40px 20px 40px',
    },
    addLessonButtonWrap: {
      display: 'flex',
      justifyContent: 'end',
      margin: '0 20px 20px 0',
    },
    linkStyle: {
      textDecoration: 'none',
    },
    daySelected: {
      backgroundColor: '#5E81F4',
      color: 'white',
      '&:hover': {
        backgroundColor: '#5E81F4 !important',
      },
    },
    headerTooltipWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '10px 15px 0 0',
    },
    editButton: {
      cursor: 'pointer',
    },
    menuTooltipItem: {
      marginBottom: '10px',
      color: 'gray',
      '&:hover': {
        color: 'black',
      },
      '&:last-child': {
        marginBottom: 0,
      },
    },
  }),
);
