import React, { FC } from 'react';
import { useStyles } from '../CreateLessonStyle';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Typography } from '@mui/material';

type imageObjectType = {
  file: File;
  localSrc: string;
};

type Props = {
  editorState: any;
  setEditorState: (editorState: EditorState) => void;
  attachedImages: imageObjectType[];
  setAttachedImages: (images: imageObjectType[]) => void;
};

const Step2: FC<Props> = (props) => {
  const classes = useStyles();
  const { editorState, setEditorState, setAttachedImages, attachedImages } = props;

  const uploadImageCallBack = (file: File) => {
    let images: imageObjectType[] = attachedImages;
    const imageObject = {
      file: file,
      localSrc: URL.createObjectURL(file),
    };
    images.push(imageObject);
    setAttachedImages(images);

    return new Promise((resolve) => {
      resolve({ data: { link: imageObject.localSrc } });
    });
  };

  return (
    <div className={`${classes.stepContentWrapper} ${classes.alignCenter}`}>
      <Typography variant="h6">Основная информация</Typography>
      <Editor // @ts-ignore
        editorState={editorState}
        wrapperClassName={classes.editorWrapper}
        editorClassName={classes.editorBody}
        onEditorStateChange={setEditorState}
        toolbar={{
          options: [
            'inline',
            'blockType',
            'fontSize',
            'fontFamily',
            'list',
            'textAlign',
            'colorPicker',
            'emoji',
            'image',
            'remove',
            'history',
          ],
          image: {
            uploadCallback: uploadImageCallBack,
            previewImage: true,
            alt: { present: false, mandatory: false },
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
          },
        }}
        localization={{
          locale: 'ru',
        }}
      />
    </div>
  );
};

export default Step2;
