import { FC } from 'react';
import LessonWidget from '../../Components/LessonWidget/LessonWidget';
import { useStyles } from './HomeWorkStyle';
import { widgetItems } from '../../MOK/widgetMock';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import QuizComponent from '../../Components/QuizComponent/QuizComponent';
import { MatchParams } from 'src/types/router';

type Props = {} & RouteComponentProps<MatchParams>;

const HomeWork: FC<Props> = (props) => {
  const classes = useStyles();
  const lessonId = props.match.params.lessonId;

  return (
    <div className={classes.wrapper1}>
      {lessonId ? (
        <QuizComponent />
      ) : (
        <LessonWidget
          alt={widgetItems[1].alt}
          description={widgetItems[1].description()}
          img={widgetItems[1].img}
          name={widgetItems[1].name}
          title={widgetItems[1].title}
          link={widgetItems[1].link}
        />
      )}
    </div>
  );
};

export default withRouter(HomeWork);
