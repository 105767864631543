import React, { FC, useEffect } from 'react';
import PersonalInfoTab from './PersonalAccountTabs/PersonalInfoTab';
import TabsWrappedLabel from '../../Components/TabPanel';
import { TeacherInfoType, triggerGetCoursesAction } from '../../ducs/teacher/actions';
import { AppStateType } from '../../ducs';
import { connect } from 'react-redux';

const MOCK_MESSAGE = 'Очень скоро здесь будет полезная информация для вас';

const PersonalAccount: FC<any> = (props) => {
  useEffect(() => props.dispatch(triggerGetCoursesAction(props.teacherData.id)), []);

  return (
    <div>
      <TabsWrappedLabel
        tabPadding={5}
        abbBarColor="default"
        centered
        indicatorColor="primary"
        textColor="primary"
        content={[<PersonalInfoTab />, MOCK_MESSAGE, MOCK_MESSAGE, MOCK_MESSAGE]}
        labeles={[
          'Личная информация',
          'Электронная почта и уведомления',
          'Загрузить свой контент',
          'Подписка',
        ]}
      />
    </div>
  );
};

type MapStateToPropsType = {
  teacherData: TeacherInfoType;
};

let MapStateToProps = (state: AppStateType) => ({
  teacherData: state.teacherData.teacherData,
});

export default connect<MapStateToPropsType, null, {}, AppStateType>(MapStateToProps)(
  PersonalAccount,
);
