import { calendarDataPost, calendarDataUpdate } from '../../API/API';
import { CALENDAR } from './actions-type';
import { StudentsType } from '../teacher/actions';
import { AppointmentModel } from '@devexpress/dx-react-scheduler';

export const triggerCreateLessonSchedule = (
  data: calendarDataPost & { setRedirect?(arg: boolean): void },
) => ({
  type: CALENDAR.TRIGGER_CREATE_LESSON_SCHEDULE,
  data,
});

export const triggerGetLessonSchedule = (id: number, students: StudentsType[]) => ({
  type: CALENDAR.TRIGGER_GET_LESSON_SCHEDULE,
  id,
  students,
});

export const getLessonScheduleAction = (
  data: (AppointmentModel & { appointmentId: number })[],
) => ({
  type: CALENDAR.GET_LESSON_SCHEDULE,
  data,
});

export const updateLessonScheduleAction = (
  id: number,
  teacherId: number,
  data: calendarDataUpdate,
  students: StudentsType[],
) => ({
  type: CALENDAR.UPDATE_LESSON_SCHEDULE,
  data,
  id,
  teacherId,
  students,
});

export const deleteLessonScheduleAction = (
  id: number,
  teacherId: number,
  students: StudentsType[],
) => ({
  type: CALENDAR.DELETE_LESSON_SCHEDULE,
  id,
  teacherId,
  students,
});
