import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '1050px',
      backgroundColor: '#F5F5FB',
    },
    question: {
      fontSize: '19px',
      backgroundColor: 'white',
      padding: '15px 20px',
      borderRadius: '15px',
      border: '2px solid black',
    },
    answerSection: {
      margin: '15px',
    },
    answer: {
      margin: '3px',
      width: '300px',
      height: '50px',
      borderRadius: '15px',
      backgroundColor: '#A2A2C2',
      color: 'white',
      '&:hover': {
        backgroundColor: '#5E81F4',
        cursor: 'pointer',
      },
    },
    button: {
      margin: '5px',
      backgroundColor: '#A2A2C2',
      color: 'white',
      width: '155px',
      height: '35px',
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: '#5E81F4',
        cursor: 'pointer',
      },
    },
    buttonWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    input: {
      width: '165px',
      borderRadius: '5px',
    },
  }),
);
