import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
      chosenChip: {
          backgroundColor: '#5E81F4 !important',
          color: 'white !important',
          '&:focus': {
              backgroundColor: '#5E81F4 !important',
              color: 'white !important',
          },
      },
  }),
);
