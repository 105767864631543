import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      height: '300px',
      width: '250px',
    },
    paperContentWrapper: {
      marginTop: '5px',
    },
    paperImg: {
      width: '100px',
      height: '100px',
      borderRadius: '20px',
    },
    paperImgWrapper: {
      display: 'flex',
      justifyContent: 'center',
      padding: '20px 0',
    },
    paperButtonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '10px',
    },
    paperButton: {
      backgroundColor: '#5E81F4',
      color: 'white',
      '&:hover': {
        backgroundColor: '#5E81F4',
      },
    },
    paperText: {
      display: 'flex',
      justifyContent: 'center',
    },
  }),
);
