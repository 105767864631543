import React, { FC, useEffect, useMemo, useState } from 'react';
import {muiStyle, useStyles} from './DashboardStatStyle';
import { Typography, Grid, Paper, Button } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import UserCard from '../../Components/UserCard/UserCard';
import { connect } from 'react-redux';
import {
  CoursesType,
  LessonsListType,
  LessonsStatsType,
  StudentsType,
  TeacherInfoType,
  triggerGetStudentsAction,
  triggerTeacherChangeLessonAction,
  triggerTeacherFutureLessonsAction,
  triggerTeacherLessonsStatsAction,
  triggerTeacherLessonsStatusesAction,
} from '../../ducs/teacher/actions';
import { AppStateType } from '../../ducs';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { ru } from 'date-fns/locale';
import Carousel from 'react-material-ui-carousel';
import { Doughnut } from 'react-chartjs-2';
import { format } from 'date-fns';
import { LessonsSearchType } from '../../API/API';

type Props = {
  teacherData: TeacherInfoType;
  lessonsStatsData: LessonsStatsType;
  teacherFutureLessons: LessonsListType[];
  lessonsList: LessonsListType[];
  lessonsStatusesList: LessonsSearchType[];
  students: StudentsType[];
  currentCourse: CoursesType;
  dispatch: any;
};

enum Status {
  Finished = 'FINISHED',
  Missed = 'MISSED',
}

const statusMap = {
  finished: 'Завершено',
  canceled: 'Отменено',
  missed: 'Пропущено',
  planned: 'Запланировано',
};

const DashboardStat: FC<Props> = (props) => {
  const {
    teacherFutureLessons,
    lessonsList,
    lessonsStatsData,
    lessonsStatusesList,
    teacherData,
    students,
    currentCourse,
    dispatch,
  } = props;
  const [disabled, setDisabled] = useState(false);
  const { id } = teacherData;
  const lessonsMinGap = 55;
  const date = new Date();
  const utcDate = new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    ),
  );
  const startDateSearchForFutureLesson = new Date(utcDate.getTime());
  startDateSearchForFutureLesson.setMinutes(
    startDateSearchForFutureLesson.getMinutes() - lessonsMinGap,
  );
  const endDateSearchForFutureLesson = new Date(utcDate.getTime());
  endDateSearchForFutureLesson.setUTCHours(23, 59, 59, 999);
  const fromStats = new Date(date.getFullYear(), date.getMonth());
  const toStats = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const getFormattedDate = (date: Date) => format(date, "yyyy-MM-dd'T'HH:mm:ss");

  useEffect(() => {
    dispatch(
      triggerTeacherFutureLessonsAction({
        fromPlannedDateTime: startDateSearchForFutureLesson.toJSON(),
        toPlannedDateTime: endDateSearchForFutureLesson.toJSON(),
        teacherIds: [id],
      }),
    );
  }, [id]);

  useEffect(() => {
    dispatch(
      triggerTeacherLessonsStatusesAction({
        toPlannedDateTime: startDateSearchForFutureLesson.toJSON(),
        teacherIds: [id],
      }),
    );
    dispatch(
      triggerTeacherLessonsStatsAction({
        id: id,
        from: getFormattedDate(fromStats),
        to: getFormattedDate(toStats),
      }),
    );
  }, [disabled, id]);

  useEffect(() => {
    dispatch(triggerGetStudentsAction(id, { teacherCourse: currentCourse }));
  }, [currentCourse?.value, id]);

  const classes = useStyles();

  const getLessonsQty = (id: number) => {
    const personalLessons = lessonsList.filter((it: any) => it.student.id === id);
    return personalLessons.length;
  };

  const carouselItems = [];
  let lastIdx = 0;
  for (let i = 0; i < teacherFutureLessons.length / 2; i++) {
    carouselItems.push([
      { ...teacherFutureLessons[lastIdx], isFirstEl: lastIdx === 0 ? true : undefined },
      teacherFutureLessons[lastIdx + 1],
    ]);
    lastIdx = lastIdx + 2;
  }

  const changeLessonStatus =
    (tId: number, lId: number, status: string, setDisabled: any) => async () => {
      await dispatch(triggerTeacherChangeLessonAction({ tId, lId, status, setDisabled }));
      dispatch(
        triggerTeacherLessonsStatusesAction({
          toPlannedDateTime: startDateSearchForFutureLesson.toJSON(),
          teacherIds: [id],
        }),
      );
    };

  const donutData = useMemo(
    () => ({
      // @ts-ignore
      labels: Object.keys(lessonsStatsData).map((it) => statusMap[it]),
      datasets: [
        {
          data: Object.values(lessonsStatsData),
          backgroundColor: ['#AED7DF', '#087592', '#D5D5D5', 'yellow'],
          borderColor: ['black'],
          borderWidth: 1,
        },
      ],
    }),
    [lessonsStatsData],
  );

  const getFullDate = (dateVal: Date) =>
    `${dateVal.getDate()}.${
      dateVal.getMonth() + 1
    }.${dateVal.getFullYear()}, ${dateVal.getHours()}:${
      (dateVal.getMinutes() < 10 ? '0' : '') + dateVal.getMinutes()
    }`;

  if (teacherData.firstName) {
    return (
      <div className={classes.dashWrapper}>
        <CssBaseline />
        <div className={classes.dashMidWrapper}>
          <div className={classes.welcome}>
            <Typography sx={muiStyle.welcomeUser}>
              Здравствуйте, {teacherData.firstName}!
            </Typography>
            <Typography sx={muiStyle.welcomeWords}>Желаем продуктивного дня!</Typography>
          </div>
          <Typography variant="h6" sx={muiStyle.paper}>
            Предстоящие уроки:
          </Typography>
          <Carousel
            autoPlay={false}
            indicators={false}
            navButtonsAlwaysInvisible={teacherFutureLessons.length < 3}
          >
            {carouselItems.map((item: any) =>
              item.map((value: any) => {
                const date = new Date(value?.fromDateTime);
                const fullDate = getFullDate(date);
                return (
                  value && (
                    <div className={classes.carouselWrap} key={value.id}>
                      <UserCard
                        imgUrl="https://mir-s3-cdn-cf.behance.net/project_modules/disp/ea7a3c32163929.567197ac70bda.png"
                        name={value.student.name}
                        lessonsNumber={getLessonsQty(value.student.id)}
                        levelName={value.student.level}
                        date={fullDate}
                        buttonName={value.isFirstEl && 'Начать урок'}
                      />
                    </div>
                  )
                );
              }),
            )}
          </Carousel>
          <Typography variant="h6" sx={muiStyle.paper}>
            Статус урока:
          </Typography>
          <Grid container sx={{
            flexGrow: 1,
            justifyContent: 'center',
            margin: '15px 0',
          }} spacing={0}>
            <Grid item sx={{width: 'fit-content'}}>
              <Grid container justifyContent="center" spacing={4}>
                {lessonsStatusesList.map((value: LessonsSearchType) => {
                  const date = new Date(value.fromDateTime);
                  const currentStudent = students.find((it) => it.id === value.studentId);
                  return (
                    <Grid key={value.id} item xs={12}>
                      <Paper elevation={3} sx={muiStyle.paperPastLesson}>
                        <div className={classes.paperPastLessonWrapper}>
                          <div className={classes.paperPastLessonImgWrapper}>
                            <img
                              className={classes.paperPastLessonImg}
                              src="https://mir-s3-cdn-cf.behance.net/project_modules/disp/ea7a3c32163929.567197ac70bda.png"
                              alt="avatar"
                            />
                          </div>
                          <div className={classes.paperPastContentWrapper}>
                            <div>
                              <Typography sx={muiStyle.paper}>
                                {`${currentStudent?.firstName} ${currentStudent?.lastName}`}
                              </Typography>
                              <Typography sx={muiStyle.paper}>
                                Дата: {getFullDate(date)}
                              </Typography>
                            </div>
                            <div>
                              <Typography sx={muiStyle.paper}>Урок:</Typography>
                              <Typography sx={muiStyle.paper}>
                                <b>{value.subject}</b>
                              </Typography>
                            </div>
                            <div className={classes.paperPastButtonWrapper}>
                              <Button
                                sx={muiStyle.paperButton}
                                disabled={disabled}
                                onClick={changeLessonStatus(
                                  id,
                                  value.id,
                                  Status.Finished,
                                  setDisabled,
                                )}
                              >
                                {'Состоялся'}
                              </Button>
                              <Button
                                sx={muiStyle.paperButton}
                                disabled={disabled}
                                onClick={changeLessonStatus(
                                  id,
                                  value.id,
                                  Status.Missed,
                                  setDisabled,
                                )}
                              >
                                {'Не состоялся'}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Paper>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className={classes.dashRightWrapper}>
          <div className={classes.logoWrapper}>
              <DayPicker locale={ru} />
          </div>
          <div className={classes.lessonStat}>
            <Typography variant="h6" sx={muiStyle.paper}>
              Статистика уроков
            </Typography>
            {donutData && <Doughnut data={donutData} type="doughnut" />}
          </div>
        </div>
      </div>
    );
  } else return <div>loading</div>;
};

type MapStateToPropsType = {
  teacherData: TeacherInfoType;
  lessonsStatsData: LessonsStatsType;
  teacherFutureLessons: LessonsListType[];
  lessonsList: LessonsListType[];
  lessonsStatusesList: LessonsSearchType[];
  currentCourse: CoursesType;
  students: StudentsType[];
};

let MapStateToProps = (state: AppStateType) => ({
  teacherData: state.teacherData.teacherData,
  teacherFutureLessons: state.teacherData.teacherFutureLessons,
  lessonsList: state.teacherData.lessonsList,
  lessonsStatusesList: state.teacherData.lessonsStatusesList,
  lessonsStatsData: state.teacherData.lessonsStatsData,
  currentCourse: state.teacherData.currentCourse,
  students: state.teacherData.students,
});

export default connect<MapStateToPropsType, null, {}, AppStateType>(MapStateToProps)(DashboardStat);
