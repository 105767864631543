import React, {FC, useEffect, useState} from 'react';
import {muiStyle, useStyles} from '../PersonalAccountStyle';
import {
    CoursesType,
    TeacherInfoType, teacherSetCourseAction,
    triggerDeleteCoursesAction,
    triggerSetCoursesAction,
    triggerTeacherInfoUpdateAction,
} from '../../../ducs/teacher/actions';
import { useFormik } from 'formik';
import { AppStateType } from '../../../ducs';
import { connect } from 'react-redux';
import { Avatar, Button, InputLabel, MenuItem, Select, Snackbar, Alert } from '@mui/material';
import avatarImg from '../../../Images/img.png';
import InputRow from '../../../Components/InputRow/InputRow';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { MatchParams } from '../../../types/router';
import StyledChip from "../../../Components/StyledChip/StyledChip";

type Props = {
  dispatch: any;
  teacherData: TeacherInfoType;
  coursesData: CoursesType[];
} & RouteComponentProps<MatchParams>;

const PersonalInfoTab: FC<Props> = (props) => {
  const classes = useStyles();
  const [currentCourse, setCurrentCourse] = useState<CoursesType>();
  const [addedCourse, setAddedCourse] = useState<CoursesType>();
  const [isSuccess, setSuccess] = useState(false);
  const [isError, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { teacherData, coursesData } = props;

  useEffect(() => {
      const course = JSON.parse(localStorage.getItem('currentCourse'));
      props.dispatch(teacherSetCourseAction(course));
      setCurrentCourse(course);
  }, [])

  useEffect(() => {
      localStorage.setItem('currentCourse', JSON.stringify(currentCourse));
      props.dispatch(teacherSetCourseAction(currentCourse));
  }, [currentCourse])

  let history = useHistory();

  const infoTab = props.match.params.info;

  const handleSnackbarClose = () => {
    setSuccess(false);
    setError(false);
  };

  const formik = useFormik({
    initialValues: {
      firstName: teacherData.firstName,
      lastName: teacherData.lastName,
      middleName: teacherData.middleName,
      country: teacherData.country,
      city: teacherData.city,
      subject: '',
      timezone: new Date().toTimeString().slice(9),
    },
    onSubmit: (values) => {
      props.dispatch(
        triggerTeacherInfoUpdateAction({
          id: teacherData.id,
          firstName: values.firstName,
          lastName: values.lastName,
          middleName: values.middleName,
          country: values.country,
          city: values.city,
          setSubmitting: setSubmitting,
          setSuccess: setSuccess,
          setError: setError,
        }),
      );
      history.push('/personalAccount');
    },
  });

  const offset = new Date().getTimezoneOffset() / 60;

  const onDeleteSubject = (item: CoursesType) => () =>
    props.dispatch(triggerDeleteCoursesAction(teacherData.id, item));

  const renderChips = (handleDelete?: (item: CoursesType) => () => void) => (
    <div>
      {teacherData?.courses?.map((item) => (
          <StyledChip
              isSelected={item?.value === currentCourse?.value}
              label={item?.value}
              onClick={() => setCurrentCourse(item)}
              onDelete={handleDelete && handleDelete(item)}
          />
      ))}
    </div>
  );

  return (
    <div>
      <div className={classes.infoForm}>
        <Avatar className={classes.avatar} src={avatarImg} />
        {infoTab ? (
          <form onSubmit={formik.handleSubmit}>
            <div className={classes.columnsContainer}>
              <InputRow
                inputsArray={[
                  {
                    name: 'lastName',
                    id: 'lastName',
                    value: formik.values.lastName,
                    onChange: formik.handleChange,
                    label: 'Фамилия',
                  },
                  {
                    name: 'firstName',
                    id: 'firstName',
                    value: formik.values.firstName,
                    onChange: formik.handleChange,
                    label: 'Имя',
                  },
                  {
                    name: 'middleName',
                    id: 'middleName',
                    value: formik.values.middleName,
                    onChange: formik.handleChange,
                    label: 'Отчество',
                  },
                ]}
              />
              <InputRow
                inputsArray={[
                  {
                    name: 'country',
                    id: 'country',
                    value: formik.values.country,
                    onChange: formik.handleChange,
                    label: 'Страна',
                  },
                  {
                    name: 'city',
                    id: 'city',
                    value: formik.values.city,
                    onChange: formik.handleChange,
                    label: 'Город',
                  },
                ]}
              />
              <div className={classes.addSubjectWrapper}>
                <div>
                  <InputLabel id="personal-info-select-label">Добавить предмет</InputLabel>
                  <Select
                    variant="standard"
                    labelId="personal-info-select-label"
                    id="subject"
                    sx={{
                        width: '223px',
                        margin: '10px 0',
                    }}
                    onChange={(e) =>
                      setAddedCourse(coursesData.filter((it) => it.value === e.target.value)[0])
                    }>
                    {coursesData.map((it) => (
                      <MenuItem value={it.value} key={it.value}>
                        {it.value}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    sx={muiStyle.addSubjectButton}
                    disabled={!addedCourse}
                    onClick={() => {
                      props.dispatch(triggerSetCoursesAction(teacherData.id, addedCourse));
                      setAddedCourse(null);
                    }}
                  >
                    Добавить
                  </Button>
                </div>
              </div>
              {renderChips(onDeleteSubject)}
            </div>
            <div style={{ fontSize: '70%', marginBottom: '20px' }}>
              {`В выбранном в настоящеее время часовом поясе ${new Date()
                .toLocaleString()
                .slice(0, 17)}`}
            </div>
            <div className={classes.buttonsWrap}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                disabled={submitting}
                sx={muiStyle.button}
              >
                Сохранить информацию
              </Button>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                sx={muiStyle.button}
                onClick={() => history.push('/personalAccount')}
              >
                Отмена
              </Button>
            </div>
          </form>
        ) : (
          <div>
            <div className={classes.text}>
              {teacherData.lastName + ' ' + teacherData.firstName + ' ' + teacherData.middleName}
            </div>
            {teacherData.country && teacherData.city && (
              <div className={classes.text}>{`${teacherData.country}, г.${teacherData.city}`}</div>
            )}
            <div className={classes.text}>
              <div>
                <b>Предмет:</b>
              </div>
              {renderChips()}
            </div>
            <div className={classes.utc}>
              Часовой пояс: UTC/GMT {offset < 0 ? offset * -1 : offset}
            </div>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => history.push('/personalAccount/info')}
              sx={muiStyle.button}
            >
              Редактировать информацию
            </Button>
          </div>
        )}
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={isSuccess}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          Информация успешно обновлена
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={isError}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          При обновление данных произошла ошибка. Попробуйте позже.
        </Alert>
      </Snackbar>
    </div>
  );
};

type MapStateToPropsType = {
  teacherData: TeacherInfoType;
  coursesData: CoursesType[];
};

let MapStateToProps = (state: AppStateType) => ({
  teacherData: state.teacherData.teacherData,
  coursesData: state.teacherData.coursesData,
});

export default connect<MapStateToPropsType, null, {}, AppStateType>(MapStateToProps)(
  withRouter(PersonalInfoTab),
);
