import React, { FC } from 'react';
import { Tabs, Tab, Typography, Box, AppBar } from '@mui/material';
import * as PropTypes from 'prop-types';

type TabsWrappedProps = {
  abbBarColor?: 'inherit' | 'primary' | 'secondary' | 'default' | 'transparent';
  centered?: boolean;
  content: any[];
  indicatorColor?: 'secondary' | 'primary';
  labeles: string[];
  tabPadding?: number;
  textColor?: 'secondary' | 'primary' | 'inherit';
};

type TabPanelProps = any;

const TabPanel: FC<TabPanelProps> = (props) => {
  const { children, value, index, tabPadding = 3, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: tabPadding }} >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index: number) => {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
};

const generateKey = (pre: number) => {
  return `${pre}_${new Date().getTime()}`;
};

const TabsWrappedLabel: FC<TabsWrappedProps> = (props) => {
  const { labeles, content, centered, indicatorColor, textColor, abbBarColor, tabPadding } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <AppBar position="static" color={abbBarColor}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="wrapped label tabs"
          centered={centered}
          indicatorColor={indicatorColor}
          textColor={textColor}
        >
          {labeles.map((label, idx) => (
            <Tab key={label} value={idx} label={label} {...a11yProps(idx)} />
          ))}
        </Tabs>
      </AppBar>
      {content.map((it: any, idx: number) => (
        <TabPanel key={generateKey(idx)} value={value} index={idx} tabPadding={tabPadding}>
          {it}
        </TabPanel>
      ))}
    </>
  );
};

export default TabsWrappedLabel;
