import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Box, Typography, Tab, Tabs, AppBar } from '@mui/material';
import { muiStyles, useStyles } from './LessonsStyle';
import TheoryTab from './LessonsTabs/TheoryTab';
import { MatchParams } from '../../types/router';
import LessonItem from '../CreateLesson/InnerComponents/LessonItem';
import { getTheoryType } from '../../API/API';
import { AppStateType } from '../../ducs';
import { connect } from 'react-redux';

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, padding: '10px 0 0 0' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

type Props = {
  lessonsTheory: getTheoryType[];
  lessonsTheoryId: getTheoryType;
  dispatch(arg: any): void;
};

const Lessons: FC<Props & RouteComponentProps<MatchParams>> = (props) => {
  const { match, lessonsTheory, lessonsTheoryId, dispatch } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const lessonId = match.params.lessonId;

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  if (Number(lessonId) === lessonsTheoryId?.id) {
    return (
      <LessonItem
        name={lessonsTheoryId.name}
        attachedLinks={lessonsTheoryId.links}
        attachedLinksVideo={lessonsTheoryId.videoLinks}
        markup={lessonsTheoryId.payload}
      />
    );
  }

  return (
    <div>
      <div className={classes.addLessonButtonWrap}>
        <NavLink to="/createLesson" className={classes.linkStyle}>
          <Button sx={muiStyles.addLessonButton}>+ Создать теорию</Button>
        </NavLink>
      </div>
      <div className={classes.tabsRoot}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="Lessons Tab"
          >
            <Tab label="Теория урока" {...a11yProps(0)} />
            <Tab label="Домашнее задание" {...a11yProps(1)} />
            <Tab label="Контрольные задания" {...a11yProps(2)} />
            <Tab label="Симуляция экзамена" {...a11yProps(3)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <TheoryTab dispatch={dispatch} lessonsTheory={lessonsTheory} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          To be done
        </TabPanel>
        <TabPanel value={value} index={2}>
          To be done
        </TabPanel>
        <TabPanel value={value} index={3}>
          To be done
        </TabPanel>
      </div>
    </div>
  );
};

type MapStateToPropsType = {
  lessonsTheory: getTheoryType[];
  lessonsTheoryId: getTheoryType;
};

let MapStateToProps = (state: AppStateType) => ({
  lessonsTheory: state.lessonsData.lessonsTheory,
  lessonsTheoryId: state.lessonsData.lessonsTheoryId,
});

export default connect<MapStateToPropsType, null, {}, AppStateType>(MapStateToProps)(
  withRouter(Lessons),
);
