import { combineReducers } from 'redux';
import { teacherReducer, watchTeacherSaga } from './teacher/reducer';
import { lessonsReducer, watchLessonsSaga } from './lessons/reducer';
import { calendarReducer, watchCalendarSaga } from './calendar/reducer';
import { all } from 'redux-saga/effects';

export const RootReducer = combineReducers({
  teacherData: teacherReducer,
  lessonsData: lessonsReducer,
  calendarData: calendarReducer,
});

export default function* rootSaga() {
  yield all([watchTeacherSaga(), watchLessonsSaga(), watchCalendarSaga()]);
}

type RootReducer = typeof RootReducer;
export type AppStateType = ReturnType<RootReducer>;
