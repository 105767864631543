import React, {FC, useCallback, useEffect} from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from '@mui/material';
import { useStyles } from '../LessonsStyle';
import { getTheoryType } from '../../../API/API';
import { NavLink } from 'react-router-dom';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  triggerDeleteTheory,
  triggerGetTheory,
  triggerGetTheoryId,
} from '../../../ducs/lessons/actions';
import {useSelector} from "react-redux";
import {AppStateType} from "../../../ducs";

type Props = {
  lessonsTheory: getTheoryType[];
  dispatch?(arg: any): void;
};


const TheoryTab: FC<Props> = (props) => {
  const { dispatch, lessonsTheory } = props;
  const classes = useStyles();
  const currentCourse = useSelector((state: AppStateType) => state.teacherData.currentCourse);

  useEffect(() => {
    dispatch(triggerGetTheory());
  }, []);

  const onLessonClick = (id: number) => () => {
    dispatch(triggerGetTheoryId(id));
  };

  const isCurrentCourseEqual = useCallback(
    (other: { owner: string; value: string }) => {
      return (
        other.owner === currentCourse.owner && other.value === currentCourse.value
      );
    },
    [currentCourse]
  );

  return (
    lessonsTheory && (
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Название темы</TableCell>
              <TableCell align="right">Класс</TableCell>
              <TableCell align="right">Раздел</TableCell>
              <TableCell align="right" classes={{ root: classes.tableCellRoot }}>
                Действие
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lessonsTheory
            // maybe remove agter call /api/contents with currentCourse in request param
            .filter((lesson) => isCurrentCourseEqual(lesson.course))
            .map((item: getTheoryType) => (
              <TableRow key={item.id}>
                <TableCell component="th" scope="row">
                  <NavLink
                    onClick={onLessonClick(item.id)}
                    to={`/lessons/${item.id}`}
                    className={classes.link}
                  >
                    {item.name}
                  </NavLink>
                </TableCell>
                <TableCell align="right">{item.course.value}</TableCell>
                <TableCell align="right">{item.sections[0].value}</TableCell>
                <TableCell align="right" classes={{ root: classes.tableCellRoot }}>
                  <Button onClick={() => dispatch(triggerDeleteTheory(item.id))}>
                    <DeleteOutlineIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  );
};

export default TheoryTab;
