import { LESSONS } from './actions-type';
import { postTheoryType } from '../../API/API';

export const triggerDeleteTheory = (id: number) => ({
  type: LESSONS.TRIGGER_DELETE_THEORY,
  id,
});

export const triggerGetTheory = () => ({
  type: LESSONS.TRIGGER_GET_THEORY,
});

export const getTheoryAction = (data: postTheoryType[]) => ({
  type: LESSONS.GET_THEORY,
  data,
});

export const triggerGetTheoryId = (id: number) => ({
  type: LESSONS.TRIGGER_GET_THEORY_ID,
  id,
});

export const getTheoryIdAction = (data: postTheoryType[]) => ({
  type: LESSONS.GET_THEORY_ID,
  data,
});

export const triggerPostTheory = (data: postTheoryType) => ({
  type: LESSONS.TRIGGER_POST_THEORY,
  data,
});

// Т.к. ответом на запрос нет никаких полезных данных, этот action пока не нужен
// export const postTheoryAction = (data: postTheoryType) => ({
//   type: LESSONS.POST_THEORY,
//   data
// });
//
// Пока используется lessonsAPI.postImage, а не saga
// export const triggerPostImage = (data: any) => ({
//   type: LESSONS.TRIGGER_POST_IMAGE,
//   data
// });
