import { FC, useState } from 'react';
import { Question, questions } from './QuestionData';
import { useStyles } from './QuizComponentStyle';

const QuizComponent: FC = () => {
  const classes = useStyles();

  const [showScore, setShowScore] = useState(false);
  const [score, setScore] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [showAnswers, setShowAnswers] = useState(false);
  const [disable, setDisable] = useState(false);
  const [input, setInput] = useState('');

  const answeredQuestionsArr: any = [];

  const handleAnswerOptionClick = (answerText: string, question: Question) => {
    if (answerText === question.correctAnswer) {
      setScore(score + 1);
    }
    answeredQuestionsArr.push({
      question: questions[currentQuestion].questionText,
      answer: answerText,
      correctAnswer: question.correctAnswer,
    });
    setAnsweredQuestions([...answeredQuestions, answeredQuestionsArr]);

    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowScore(true);
    }
  };
  return (
    <div className={classes.wrapper}>
      {showScore ? (
        <div className={classes.buttonWrapper}>
          <div className="score-section">
            <h2>
              Правильных ответов {score} из {questions.length}
            </h2>
          </div>
          {disable ? (
            <div>
              <button
                className={classes.button}
                onClick={() => {
                  setShowAnswers(false);
                  setDisable(false);
                }}
              >
                Скрыть результаты
              </button>
            </div>
          ) : (
            <div>
              <button
                className={classes.button}
                onClick={() => {
                  setShowAnswers(true);
                  setDisable(true);
                }}
              >
                Показать результаты
              </button>
            </div>
          )}
          <div>
            {showAnswers
              ? answeredQuestions.map((u) => {
                  return (
                    <div>
                      <ul>
                        <li>{u[0].question}</li>
                        <li>Ваш ответ: {u[0].answer}</li>
                        <li>Правильный ответ: {u[0].correctAnswer}</li>
                      </ul>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      ) : (
        <>
          <h3>
            <span>Вопрос {currentQuestion + 1}</span>/{questions.length}
          </h3>
          <div className={classes.question}>{questions[currentQuestion].questionText}</div>
          {questions[currentQuestion].isInput ? (
            <div className={classes.answerSection}>
              <div>
                <input
                  className={classes.input}
                  type="text"
                  onChange={(e) => setInput(e.currentTarget.value)}
                />
              </div>
              <div className={classes.buttonWrapper}>
                <button
                  className={classes.button}
                  onClick={() => handleAnswerOptionClick(input, questions[currentQuestion])}
                >
                  Ответить
                </button>
              </div>
            </div>
          ) : (
            <div className={classes.answerSection}>
              {questions[currentQuestion].answerOptions.map((answerOption) => (
                <div>
                  <button
                    className={classes.answer}
                    onClick={() =>
                      handleAnswerOptionClick(answerOption.answerText, questions[currentQuestion])
                    }
                  >
                    {answerOption.answerText}
                  </button>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default QuizComponent;
