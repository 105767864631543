import React, {FC} from 'react';
import {useStyles} from './StyledChipStyle';
import {Chip} from '@mui/material';

type Props = {
  isSelected: boolean;
  label: string;
  onClick(): void;
  onDelete?(): void;
};

export const StyledChip: FC<Props> = ({isSelected, onClick, onDelete, label}) => {
  const classes = useStyles();

  return (
      <Chip
          className={`${isSelected && classes.chosenChip}`}
          sx={{
            height: '25px',
            margin: '5px 5px 0 0',
          }}
          label={label}
          onClick={onClick}
          onDelete={onDelete}
          size="small"
      />
  )
};

export default StyledChip;
